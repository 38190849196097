import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentRow from "./AssessmentRow";
import TableHeader from "./TableHeader";
import { Button, Table, TableBody, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const SelfAssessment = ({
  questionsList,
  sectionTitle,
  scale,
  sectionIndex,
  subSectionKey,
  updateFunction,
  previousResponse,
}) => {
  const classes = useStyles();
  const [response, setResponse] = React.useState(
    previousResponse === null || previousResponse === undefined
      ? new Array(questionsList.length).fill("c")
      : previousResponse
  );
  const [disabled, setDisabled] = React.useState(
    previousResponse !== null && previousResponse !== undefined
  );
  const [showSubmit, setShowSubmit] = React.useState(
    previousResponse === null || previousResponse === undefined ? true : false
  );
  const [showAlert, setShowAlert] = React.useState(false);

  const handleResponse = (index, res) => {
    setResponse(
      response.map((item, j) => {
        if (j === index) return res;
        return item;
      })
    );
  };

  const handleSubmit = () => {
    updateFunction(response, sectionIndex, subSectionKey);
    setShowSubmit(false);
    setDisabled(true);
    setShowAlert(true);
  };

  return (
    <Box className={classes.root}>
      <Table>
        <TableHeader sectionTitle={sectionTitle} scale={scale} />
        <TableBody>
          {questionsList.map((ques, idx) => (
            <AssessmentRow
              disable={disabled}
              selected={response[idx]}
              question={ques}
              key={idx}
              lightBg={idx % 2 === 1}
              handleResponse={handleResponse}
              index={idx}
            />
          ))}
        </TableBody>
      </Table>
      <Box className={classes.submit}>
        {showSubmit && (
          <Button
            onClick={handleSubmit}
            style={{ marginBottom: "2rem" }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        )}
        {showAlert && (
          <Alert
            icon={<CheckCircleOutlineIcon fontSize="inherit" color="primary" />}
            severity="info"
            className={classes.alert}
          >
            <AlertTitle>Success! You've submitted your answer.</AlertTitle>
          </Alert>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    width: "90%",
    margin: "auto",
  },
  submit: {
    marginTop: "2rem",
    textAlign: "center",
  },
  alert: {
    marginBottom: "3rem",
  },
});

export default SelfAssessment;
