
export var OralPostSurveyForm = {
    "formHeader": "Oral Communication – Experience Survey & Certificate of Completion",
    "formType": 'oralPostSurvey',
    "formText": {
        "0": "Now that you have completed the Oral Communication Module, please complete the following questions based on what you have learned. Your responses will be anonymized and used only for research purposes. If you have any questions, please contact: aplusuoft.contact@gmail.com",
    },
    "formSections": {
        "0": {
            "sectionHeader": "",
            "sectionText": {
            },
            "sectionQuestion": {
                "0": {
                    "qText": "1.a How well can you do the following in English in an academic setting (university or high school)? <br/><br/> English Listening",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Understand the main ideas of lectures and conversations.", "Understand important facts and details of lectures and conversations.", "Understand the relationships among ideas in a lecture or conversation.", "Understand a speaker's attitude or opinion about what he or she is saying.", "Recognize why a speaker is saying something.", "Understand different viewpoints.", "Understand subject-specific vocabulary.", "Understand idiomatic language (e.g. a piece of cake)", "Understand culturally-specific views or ideas"],
                    "response": [null, null, null, null, null, null, null, null, null]
                },
                "1": {
                    "qText": "1.b Which of the following skills would you like to improve? Choose all that apply. ",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Understanding the main ideas of lectures and conversations.", "Understanding important facts and details of lectures and conversations.", "Understanding the relationships among ideas in a lecture conversation.", "Understanding a speaker's attitude or opinion about what he or she is saying.", "Recognizing why a speaker is saying something.", "Understanding different viewpoints", "Understanding subject-specific vocabulary", "Understanding idiomatic language (e.g. a piece of cake)", "Understanding culturally-specific views or ideas"],
                    "response": [null, null, null, null, null, null, null, null, null]
                },
                "2": {
                    "qText": "2.a How well can you do the following in English in an academic setting (university or high school)? <br/><br/> English Speaking",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Speak for about one minute in response to a question.", "Orally summarize information from a talk I have listened to in English.", "Orally summarize information that I have read in English. ", "Orally compare different viewpoints ", "Use subject-specific vocabulary in presentations or discussions", "Use idiomatic language (e.g. a piece of cake)", "Talk about culturally-specific views or ideas"],
                    "response": [null, null, null, null, null, null, null]
                },
                "3": {
                    "qText": "2.b. Which of the following skills would you like to improve? Choose all that apply. ",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Speak for about one minute in response to a question.", "Orally summarize information from a talk I have listened to in English.", "Orally summarize information that I have read in English. ", "Orally compare different viewpoints ", "Use subject-specific vocabulary in presentations or discussions", "Use idiomatic language (e.g. a piece of cake)", "Talk about culturally-specific views or ideas"],
                    "response": [null, null, null, null, null, null, null]
                },
                "4": {
                    "qText": " 3. How helpful was the presentation task? ",
                    "qType": "mcq",
                    "options": ["Not at all helpful", "Not very helpful", "Helpful", "Very helpful", "Extremely helpful"],
                    "response": null
                },
                "5": {
                    "qText": "4. Add any additional comments or explanation here regarding how helpful the presentation was: ",
                    "qType": "text",
                    "options": [],
                    "response": null
                },
                "6": {
                    "qText": "5. How useful did you find the resources in the module?",
                    "qType": "mcq",
                    "options": ["Not at all useful", "Not very useful", "Useful", "Very useful", "Extremely useful"],
                    "response": null
                },
                "7": {
                    "qText": "6.  Add any additional comments or explanation here regarding resources:",
                    "qType": "text",
                    "options": [],
                    "response": null
                },
                "8": {
                    "qText": "7. Is there anything about oral communication that you had hoped to learn but was not covered in this module? ",
                    "qType": "text",
                    "options": [],
                    "response": null
                },
                "9": {
                    "qText": "8. Are there any other suggestions that you would like to add? ",
                    "qType": "text",
                    "options": [],
                    "response": null
                }
            }
        },
        "1": {
                "sectionHeader": "Academic Literacy",
                "sectionText": {
                },
                "sectionQuestion": {
                    "0": {
                        "qText": "1. How well can you do the following in English (university or high school)? <br/><br/> English Reading",
                        "qType": "table",
                        "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                        "options": ["Distinguish main ideas from examples.", "Distinguish primary sources from secondary ones. ", "Distinguish facts from opinions. ", "Identify the lecturer's perspectives and stances. ", "Reflect on the lecturer’s perspectives critically. ", "Understand different viewpoints.", "Identify biases in course materials (e.g. readings). ", "Form own opinions.", "Interpret information from diagrams, graphs, charts, or formulas."],
                        "response": [null, null, null, null, null, null, null, null, null]
                    },
                    "1": {
                        "qText": "Which of the following skills would you like to improve? Choose all that apply. ",
                        "qType": "singleRowTable",
                        "tableTitles": ["I would like to improve this skill"],
                        "options": ["Distinguish main ideas from examples.", "Distinguish primary sources from secondary ones. ", "Distinguish facts from opinions. ", "Identify the lecturer's perspectives and stances. ", "Reflect on the lecturer’s perspectives critically. ", "Understand different viewpoints.", "Identify biases in course materials (e.g. readings). ", "Form own opinions.", "Interpret information from diagrams, graphs, charts, or formulas."],
                        "response": [null, null, null, null, null, null, null, null, null]
                    },
                }
            },
    },
    "formAgreement": {
        "active": false,
        "textLine": "I agree/Agree/I consent",
        "response": false
    }
}

