import React from "react";
import Resources from "../../../components/Wrappers/Docs/Resources"
import DashFrame from '../../../components/DashFrame/DashFrame'
import '../../../CSS_share.css'

function ResourcePage( props ) {
    return (
        <DashFrame>
            <Resources/>
        </DashFrame>
    );
}
export default ResourcePage;