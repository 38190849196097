import { Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import React from "react";
import { Container } from "react-bootstrap";
import DashFrame from '../../../components/DashFrame/DashFrame'
import '../../../CSS_share.css'

export default function IncompleteModule( props ) {
    return (
        <DashFrame>
            <Container>
            <div style={{display: 'grid', placeItems: 'center'}}>
             <h1>Incomplete Module</h1>
                <Error style={{fontSize: '15rem'}} color='error'/>
                <Typography>Looks like you haven't answered all the questions for the module. Please answer all the questions and click the <b>{"Survey & Certificate"}</b> button again for your certificate.</Typography>
            </div>
            </Container>
        </DashFrame>
    );
}