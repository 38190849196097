import React from "react";
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import { CardActionArea, Card, Grid } from "@material-ui/core";
import { MenuBook, RecordVoiceOver, Assignment } from "@material-ui/icons";


const Resources = () => {
    return (

        <Container style={{ 'padding-top': "40px" }}>
            <Typography>
            <h2>Resources</h2>
            <hr /> <br></br>
            <Grid container justify='space-around'>
                <Grid item xs={3}>
                    <Card component={Link} to='resources/academicIntegrity' style={{padding: 0, textDecoration: 'none', height: '11rem', width: '100%'}}>
                        <CardActionArea style={{textAlign: 'center', padding: '1rem'}}>
                        <MenuBook color='primary' style={{fontSize: '5rem', marginBottom: '1rem'}}/>
                            <Typography variant='h6' align='center' style={{marginBottom: '1rem'}}>
                                <strong>Academic Integrity</strong>
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={3}>
                    <Card component={Link} to='resources/oral' style={{padding: 0, textDecoration: 'none', height: '11rem', width: '100%'}}>
                        <CardActionArea style={{textAlign: 'center', padding: '1rem'}}>
                        <RecordVoiceOver color='primary' style={{fontSize: '5rem', marginBottom: '1rem'}}/>
                            <Typography variant='h6' align='center' style={{marginBottom: '1rem'}}>
                                <strong>Oral</strong>
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={3}>
                    <Card component={Link} to='resources/written' style={{padding: 0, textDecoration: 'none', height: '11rem', width: '100%'}}>
                        <CardActionArea style={{textAlign: 'center', padding: '1rem'}}>
                        <Assignment color='primary' style={{fontSize: '5rem', marginBottom: '1rem'}}/>
                            <Typography variant='h6' align='center' style={{marginBottom: '1rem'}}>
                                <strong>Written</strong>
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            </Typography>
        </Container>
    );
}

export default Resources;