import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import SurveyForm from "../../components/Util/SurveyForm/SurveyForm.js";
// Form Data

import{WritingPostSurveyForm as WritingPostSurveyFormData} from '../../static/forms/WritingPostSurvey';

class WrittenPostSurveyForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            moduleComplete: false,
        }
    }

    static getDerivedStateFromProps(props, state){
        if (props.moduleData !== undefined || Object.keys(props.moduleData).length !== 0){
            try {
                let complete = props.moduleData.userState.progress >= props.moduleData.userState.totalQuestions
                return {moduleComplete: complete}
            }
            catch(err) {
                console.log(err)
                return null
            }   
        }
        return null
    }


    render() {
        if (this.state.moduleComplete === false) { return <Redirect to='/incompleteModule'></Redirect>; }

        return (
            <SurveyForm 
                form={WritingPostSurveyFormData} 
                userId={this.props.auth.uid} 
                finishOnBoarding={true}
                redirect={'/overview'}
            >
            </SurveyForm>  
        );
    }
}

const mapStateTOProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        moduleData: state.moduleData.moduleContent.content
    }
}

export default connect(mapStateTOProps)(WrittenPostSurveyForm);