import React from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import '../../../CSS_share.css'
import AcademicIntegrityResources from "../../../components/Wrappers/Docs/AcademicIntegrityResources";

function AIResourcePage( props ) {
    return (
        <DashFrame>
            <AcademicIntegrityResources/>
        </DashFrame>
    );
}
export default AIResourcePage;