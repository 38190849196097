import React from "react";
import Timer from "./Timer";
import PdfRender from "../PDFRender/PdfRender";
import Quiz from "./Quiz";


class ReadAnArticle extends React.Component {
  state = {
    finishRead: false,
    quizSubmit: false,
  };

  onFinish = (timeTaken) => {
    console.log(timeTaken);
    this.setState({ finishRead: true });
  };

  onQuizSubmit = (response, sectionIndex, subSectionKey) => {
    this.props.updateFunction(response, sectionIndex, subSectionKey)
    this.setState({quizSubmit: true});
  }

  render() {
    
    return !this.state.finishRead ? (
      <>
        {instructions}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Timer onFinish={this.onFinish} />
        </div>
        <PdfRender
          style={{ height: "1080px" }}
          draft={false}
          pdf={this.props.pdf[0]}
          stretch={true}
        />
      </>
    ) : 
    this.props.previousResponse || this.state.quizSubmit ? (<span>You've already answered this part of the quiz. You can move onto the next part!</span>): (
      <Quiz updateFunction={this.props.updateFunction} sectionIndex={this.props.sectionIndex} subSectionKey={this.props.subSectionKey}/>
    );
  }
}

const instructions = (
  <>
    <p>
      You will read an excerpt from an article. All of the tasks in the module
      will be based on this reading. You will be able to access and refer back
      to the article at any time throughout the tasks. For this task, you should
      take notes as you read. When you read, focus on the author’s key points
      and major supporting details.
    </p>
    <p>
      Before reading the article, briefly take a look at the article and think
      about how difficult it might be for you to comprehend.
    </p>
    <p>
      An important academic skill is time management and making sure you use
      your time effectively. For this task, <b>set the timer</b> for how long
      you think it will take you to read and take notes for this article. Use
      the buttons to set your time. When you are ready, click <b>Start Timer</b>{" "}
      and the timer will start counting down
    </p>
    <p>
      Click on the <b>Finished</b> button when you have completed the reading
      and are ready to move to the next task.
    </p>
  </>
);

export default ReadAnArticle;
