import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField'
import {Radio, RadioGroup, FormControlLabel, FormControl,
    Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Container } from '@material-ui/core/';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Certificate from "../../Util/Certificate/Certificate";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ReactHtmlParser from 'react-html-parser'; 

import {submitFormData, completeOnboarding} from '../../../service/forms/dbService.js';

const useStyles = makeStyles({
    root: {
      padding: 10,
      display: "flex",
    },
    bullet: {
      display: 'flex',
    },
    title: {
      fontSize: 14,
    },

  });

  const certForms = ['oralPostSurvey', 'academicIntegrityPostSurvey', 'writtenPostSurvey'];
  
class SurveyForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleContinue = this.handleContinue.bind(this);
      this.downloadCertificate = this.downloadCertificate.bind(this);
      this.state = { demoFlag: false, formData: this.props.form, showTextview: false, showCert: false, openSubmitDialog: false, qAnswered: 0, errModal: false}
      this.wrapper = React.createRef();
    
    }

    downloadCertificate(){
        this.setState({showCert: true}, () => {
            window.scrollTo(0,0);
            const input = document.getElementById('completion-cert').children;
            html2canvas(input[0]).then((canvas) => {
                const pdf = new jsPDF('l','pt','a4');
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
                pdf.save("certificate.pdf");
                this.setState({showCert: false})
            }).catch((err) => {
                console.log(err)
            });
        })
    }
  
    handleChange(event, section, question) {
    
        try {
            let formCopy = {...this.state.formData};
            
            if (event.target.name.substring(0,5) === 'table'){
            
                let question_index = event.target.name.substring(event.target.name.length-1,event.target.name.length)
                if (formCopy.formSections[section].sectionQuestion[question].response[parseInt(question_index)] === null){
                    this.setState({qAnswered: this.state.qAnswered + 1})
                }
                formCopy.formSections[section].sectionQuestion[question].response[parseInt(question_index)] = parseInt(event.target.value)
                

            } else if (event.target.name.substring(0,6) === 'single') {
                let question_index = event.target.name.substring(event.target.name.length-1,event.target.name.length)
                if (formCopy.formSections[section].sectionQuestion[question].response[parseInt(question_index)] === null){
                    formCopy.formSections[section].sectionQuestion[question].response[parseInt(question_index)] = true
                    // this.setState({qAnswered: this.state.qAnswered + 1})

                }else{ 
                    formCopy.formSections[section].sectionQuestion[question].response[parseInt(question_index)] = null
                }
            }
            else {
                if (formCopy.formSections[section].sectionQuestion[question].response === null && formCopy.formSections[section].sectionQuestion[question].optional !== true){
                    this.setState({qAnswered: this.state.qAnswered + 1})

                } 
                formCopy.formSections[section].sectionQuestion[question].response = parseInt(event.target.value)

            }    
            if (JSON.stringify(formCopy.formSections[section].sectionQuestion[question].options) === JSON.stringify(["Yes", "No"])){
                if (formCopy.formSections[section].sectionQuestion[question].invokeAction !== undefined){
       
                    if (this.props.registerAction !== null) {
                        if (formCopy.formSections[section].sectionQuestion[question].response === 0){
                            this.props.registerAction(formCopy.formSections[section].sectionQuestion[question].invokeAction)
                        } else {
                            this.props.registerAction(null)
                        }
                    }
                }
            }
            this.setState({formData: formCopy})
            console.log("WHAT IS THIS: ", event.target, section, question, this.state.qAnswered)
    } catch(error) {
        console.log(error)
    }

        
    }

    handleContinue(e) {
        if (this.props.form.formType === 'demographic') {
            try {
                completeOnboarding(this.props.auth.uid).then(function(){
                 })
            } catch (error) {
                console.log(error)
            }
        }
    }

    handleOtherClick(event) {
        this.setState({showTextview: true})
    }

    handleResetTextview(event, response) {
        response = event.target.value

        this.setState({showTextview: false})
    }
  
    async handleSubmit(event) {
        event.preventDefault()

        if(this.state.formData.formType === 'demographic' && this.state.qAnswered < 50){
            console.log("ERROR!!!!!!!!!!!!!!!!!")
            this.setState({errModal: true})
        }
        else if(this.state.formData.formType === 'consent' && this.state.qAnswered < 3){
            console.log("ERROR!!!!!!!!!!!!!!!!!")
            this.setState({errModal: true})            
        }
        else if(this.state.formData.formType === 'academicIntegrityPostSurvey' && this.state.qAnswered < 20){
            console.log("ERROR!!!!!!!!!!!!!!!!!")
            this.setState({errModal: true})  
        } else if (this.state.formData.formType === 'writtenPostSurvey' && this.state.qAnswered < 23){
            console.log("ERROR!!!!!!!!!!!!!!!!!")
            this.setState({errModal: true})  
        }
        else{
            console.log("HERE: ", this.state.formData)
            var redirectTo = this.props.redirect
            let formCopy = {...this.state.formData};
            formCopy["formSignedAt"] = Date.now();
            
            if (certForms.includes(this.props.form.formType)) {
                this.setState({showCert: true}, () => {
                    window.scrollTo(0,0);
                    const input = document.getElementById('completion-cert').children;
                    html2canvas(input[0]).then((canvas) => {
                        const pdf = new jsPDF('l','pt','a4');
                        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
                        pdf.save("certificate.pdf");
                        this.setState({showCert: false})
                    }).catch((err) => {
                        console.log(err)
                    });
                })
            }
            this.setState({formData: formCopy, openSubmitDialog: true})
        // Submit form data and then redirect to next page
            await submitFormData(this.props.userId, this.props.form.formType+"Form", this.state.formData)
        }
    }
    
    render() {
        let spacedOut = false;
        
        return (
            <Container>
            <Dialog onClose={() => this.setState({openSubmitDialog: false})} open={this.state.openSubmitDialog}>
            {this.props.form.formType === 'demographic' ?                 
                <><DialogTitle>Thank you! 🎉</DialogTitle>
                <DialogContent>Thank you so much for completing the student survey. Your input is valuable for our research and platform improvement. Now, we hope you enjoy the platform!</DialogContent>
                </>
            :
                <>
                <DialogTitle>Continue</DialogTitle>
                <DialogContent>Please press the button below to continue to the next page.</DialogContent></>
                }
            <DialogActions>
                <Link style={{ textDecoration: 'none', color:"#FFFFFF", padding:"0px" }} to={this.props.redirect}>
                        <Button variant="contained" color="primary" onClick={this.handleContinue}>Continue</Button>
                </Link>
            </DialogActions>
            </Dialog>
            <Box  alignItems="center" style={{padding: '20px'}} margin="70px">
            <Card className={useStyles.root}>
                <CardContent>
                    {/* <Typography className={useStyles.title} component={'span'} color="textSecondary" gutterBottom> */}
                    <form onSubmit={this.handleSubmit}>

                        <h2 style={{paddingTop: '20px'}}>{this.props.form.formHeader}</h2><hr/>

                        {Object.keys(this.props.form.formText).map((text) => 
                            <div>
                            <p >{ReactHtmlParser(this.props.form.formText[text])}</p>
                            </div>
                        )}

                        {Object.keys(this.props.form.formSections).map((section) => 
                            <div>
                                <h3 style={{paddingTop: '20px'}}>{ReactHtmlParser(this.props.form.formSections[section].sectionHeader)}</h3>
                                <hr/>
                                
                                {Object.keys(this.props.form.formSections[section].sectionText).map((sectiontext) => 
                                    <div>
                                        <p>{this.props.form.formSections[section].sectionText[sectiontext].split("\n").map((item)=> (<>{item}<br/></>))}</p>
                                    </div>
                                )}
                                {Object.keys(this.props.form.formSections[section].sectionQuestion).map((question) => 
                                    (this.props.form.formSections[section].sectionQuestion[question].qType === 'text' ?  
                                        <div>
                                        <h5 style={{paddingTop: '20px'}}>{this.props.form.formSections[section].sectionQuestion[question].qText}</h5>
                                        <TextField
                                            required
                                            id={question}
                                            label="Provide your response here"
                                            style = {{width: 300}}
                                            variant={'outlined'}
                                            onChange={(event) => 
                                                {if (this.state.formData.formSections[section].sectionQuestion[question].response === null && this.state.formData.formSections[section].sectionQuestion[question].optional !== true) { this.setState({qAnswered: this.state.qAnswered+1}) }
                                                this.state.formData.formSections[section].sectionQuestion[question].response = event.target.value}}
                                        />
                                        <br/><br/>
                                        </div> :
                                    this.props.form.formSections[section].sectionQuestion[question].qType === 'mcq' ?
                                        <div>
                                        
                                        <h5 style={{paddingTop: '20px'}}>{ReactHtmlParser(this.props.form.formSections[section].sectionQuestion[question].qText)}</h5>
                                       
                                        <FormControl component="fieldset">
                                            <RadioGroup 
                                                name={"section-"+section+"question-"+question} 
                                                value={parseInt(this.state.formData.formSections[section].sectionQuestion[question].response)} 
                                                onChange={(event) => this.handleChange(event, section, question)} 
                                            >
                                            
                                                {this.props.form.formSections[section].sectionQuestion[question].options.map((option, index) => 
                                                <div>
                                                    
                                                    {/* {option != 'Other' ? */}
                                                        <FormControlLabel 
                                                        key={index} 
                                                        value={parseInt(index)} 
                                                        control={<Radio />} 
                                                        label={option} />
                                                    {/* : <FormControlLabel 
                                                        key={index} 
                                                        value={parseInt(index)} 
                                                        control={<div> <Radio/>  
                                                            <TextField required
                                                            id={question}
                                                            label="If other, provide your response here"
                                                            style = {{width: 300}}
                                                            variant={'outlined'}
                                                            onChange={(event) => this.state.formData.formSections[section].sectionQuestion[question].response === event.target.value}>
                                                            </TextField> 
                                                            </div>} 
                                                        // label={option}
                                                        /> 
                                                    } */}
                                                    
                                                </div>)}
                                                </RadioGroup>
                                            </FormControl>
                                            </div> :
                                         this.props.form.formSections[section].sectionQuestion[question].qType === 'table' ?
                                         <div>
                                             <h5 style={{paddingTop: '40px'}}>{ReactHtmlParser(this.props.form.formSections[section].sectionQuestion[question].qText)}</h5>
                                             <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                    <TableRow>

                                                        <TableCell> {" "}</TableCell>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup 
                                                                name={""} 
                                                                // value={this.props.form.formSections[section].sectionQuestion[question].response[0]} 
                                                                style={{ display: 'flex', justifyContent: 'space-between', width: '500px' }}
                                                                row
                                                        >
                                                        {this.props.form.formSections[section].sectionQuestion[question].tableTitles.map((title) => {
                                                            spacedOut = title === "Very low" || title === "Below average" || title === "Average" || title === "Above average" || title === "Very high"
                                                            return (spacedOut) ?                                                             
                                                            <TableCell style={{marginRight: '10px', marginLeft: '10px', width: '12%'}}>{title}</TableCell> : <TableCell style={{marginRight: '10px', marginLeft: '10px',width: '15%'}}>{title}</TableCell>
                                                        }
                                                        )} 
                                                            </RadioGroup>
                                                         </FormControl>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.props.form.formSections[section].sectionQuestion[question].options.map((option, index) =>
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {option}
                                                            </TableCell>

                                                            <FormControl component="fieldset">
                                                            <RadioGroup 
                                                                id={"table"}
                                                                name={"table-section-"+section+"question-"+question+"-"+index} 
                                                                value={this.props.form.formSections[section].sectionQuestion[question].response[index]} 
                                                                style={{ display: 'flex', justifyContent: 'space-between'}}
                                                                onChange={(event) => this.handleChange(event, section, question)}
                                                                row
                                                            > 
                                                                
                                                                <TableCell  style={{'marginRight': '10px', 'marginLeft': '10px',width: '15%'}}> <FormControlLabel key={option + "-0"} value={0} 
                                                                                                                    control={<Radio id={option + "-0"} ></Radio>}/>
                                                                </TableCell>
                                                                <TableCell  style={{'marginRight': '10px', 'marginLeft': '10px',width: '15%'}}> <FormControlLabel key={option + "-1"} value={1} 
                                                                                                                control={<Radio id={option + "-1"} ></Radio> } 
                                                                                                                />
                                                                </TableCell>
                                                                <TableCell  style={{'marginRight': '10px', 'marginLeft': '10px',width: '15%'}}> <FormControlLabel key={option + "-2"} value={2} 
                                                                                                                control={<Radio id={option + "-2"} ></Radio>} 
                                                                                                                />
                                                                </TableCell>
                                                                <TableCell  style={{'marginRight': '10px', 'marginLeft': '10px',width: '15%'}}> <FormControlLabel key={option + "-3"} value={3} 
                                                                                                                control={<Radio id={option + "-3"} ></Radio>} 
                                                                                                                />
                                                                </TableCell>
                                                                <TableCell  style={{'marginRight': '10px', 'marginLeft': '10px',width: '15%'}}> <FormControlLabel key={option + "-4"} value={4} 
                                                                                                                control={<Radio id={option + "-4"} ></Radio>} 
                                                                                                                />
                                                                </TableCell>
                                                            </RadioGroup>
                                                        </FormControl>
                                                        </TableRow>
                                                    )}
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>
                                             
                                         </div> : 
                                        this.props.form.formSections[section].sectionQuestion[question].qType === 'singleRowTable' ?
                                        <div>
                                            <h5 style={{paddingTop: '40px'}}>{this.props.form.formSections[section].sectionQuestion[question].qText}</h5>
                                             <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                    <TableRow>

                                                        <TableCell> {" "}</TableCell>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup 
                                                                name={""} 
                                                                // value={this.props.form.formSections[section].sectionQuestion[question].response[0]} 
                                                                style={{ display: 'flex' }}
                                                                row
                                                        >
                                                        {this.props.form.formSections[section].sectionQuestion[question].tableTitles.map((title) =>
                                                            (title === "Very low" || title === "Below average" || title === "Average" || title === "Above average" || title === "Very high") ?
                                                            
                                                            <TableCell>{title}</TableCell> : <TableCell style={{'marginRight': '10px', 'marginLeft': '10px'}}>{title}</TableCell>
                                                            
                                                        )} 
                                                            </RadioGroup>
                                                         </FormControl>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.formData.formSections[section].sectionQuestion[question].options.map((option, index) =>
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {option}
                                                            </TableCell>

                                                            <FormControl component="fieldset">
                                                            <RadioGroup 
                                                                id={"singleRowTable"}
                                                                name={"singleRowTable-section-"+section+"question-"+question+"-"+index} 
                                                                style={{ display: 'flex' }}
                                                                onClick={(event) => this.handleChange(event, section, question)}
                                                                row
                                                            > 
                                                                
                                                                <TableCell  style={{'marginRight': '10px', 'marginLeft': '30px'}}> <FormControlLabel key={option + "-0"} checked={this.state.formData.formSections[section].sectionQuestion[question].response[index]} 
                                                                                                                    control={<Radio id={option + "-0"} style={{'margin': '15%'}} ></Radio>} 
                                                                                                                    style={{ width: 'auto' }}
                                                                />
                                                                </TableCell>
                                                                
                                                            </RadioGroup>
                                                        </FormControl>
                                                        </TableRow>
                                                    )}
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>


                                        
                                        </div> : 
                                            
                                        <div>
                                        <h5 style={{paddingTop: '20px'}}>{ReactHtmlParser(this.props.form.formSections[section].sectionQuestion[question].qText)}</h5>

                                        <FormControl>
                                            <Select style = {{width: 300}} ref={this.wrapper} 
                                            onChange={(event) => 
                                                {
                                                if (this.state.formData.formSections[section].sectionQuestion[question].response === null) { this.setState({qAnswered: this.state.qAnswered+1}) }
                                                this.state.formData.formSections[section].sectionQuestion[question].response = event.target.value}}
                                                >
                                                {this.props.form.formSections[section].sectionQuestion[question].options.map((option, index) => 
                                                    <MenuItem key={index} value={index}>{option}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <br/><br/>
                                        </div> 
                                    )
                                )}

                            </div>
                        )}  

                    </form>
                    {/* </Typography> */}
                </CardContent>
                <CardActions style={{justifyContent: 'left', padding: '20px'}}>
                    {this.props.form.formAgreement.active && (
                        <select id="Consent" padding="10px" required>
                            <option value="agree">{this.props.form.formAgreement.textLine}</option>
                            <option value="notagree">{"I do not consent."}</option>
                        </select>
                    )}
                    
                    <Button variant="contained" color="primary" onClick={(event) =>  this.handleSubmit(event)}>
                    {"Submit"}
                    </Button>
                    {this.state.errModal ?

                        <div style={{color: "red"}}>Error! One or more of the options are not filled out.</div>



                    : ""}
                    {this.state.showCert && <div style={{width: "25px", height: "100px"}} id="completion-cert">
                    <Certificate title={this.props.form.formHeader} type={this.props.form.formType}></Certificate></div>}
                </CardActions>

            </Card>

            </Box>

            </Container>)

};
}

const mapStateTOProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(SurveyForm);




