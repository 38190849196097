import React, { useState } from "react";

import Record from "../Asr/record/Record.js";
import { makeStyles } from "@material-ui/core/styles";
import Upload from "../Asr/upload/Upload.js";
import PdfRender from "../PDFRender/PdfRender.js";
import { storage } from "../../../FirestoreRedux";

function PracticePresenting(props) {
  const classes = useStyles();
  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [pdf, setPdf] = useState(null);

  React.useEffect(() => {
    if (fileName !== "") {
      storage
        .ref("oral/practicePresenting/" + props.uid + "/" + fileName)
        .getDownloadURL()
        .then(function (returnUrl) {
          setUrl(returnUrl);
        });
    }
  }, [fileName]);

  return (
    <div className={classes.root}>
      <div className={classes.upload}>
        {pdf !== null ? (
          <PdfRender pdf={pdf} />
        ) : (
          <Upload
            saveLocation={props.saveLocation + "presentations/"}
            onSubmit={(file) => {
              setPdf(file);
            }}
          />
        )}
      </div>
      <div className={classes.record}>
        <Record
          uploadOnStop={true}
          resourceButtonLink={props.resourceButtonLink}
          visual={false}
          feedback={props.feedback}
          saveLocation={props.saveLocation + "audio/"}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: "90%",
    display: "flex",
    marginLeft: "6rem",
  },
  record: {
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#7270a148",
    margin: "1rem",
    width: "30%%",
  },
  upload: {
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#7270a148",
    margin: "1rem",
    width: "70%",
  },
  alert: {
    marginBottom: "3rem",
  },
});

export default PracticePresenting;
