import React, { useCallback, useMemo, useState } from "react";
// import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getEntries, RawResult, responseIsNull } from "./DetailedDashboard";

const AdminView = ({ rawResults }) => (
  <Grid container spacing={6} direction="column" alignItems="center">
    <Grid item>
      <BigNumbers rawResults={rawResults} />
    </Grid>
    <Grid container direction="column" item width="100%">
      <Typography align="center" variant="h5">
        Submissions by Month
      </Typography>
      <SubmissionOverview rawResults={rawResults} />
    </Grid>
    <Grid item container direction="column" width="100%">
      <Typography align="center" variant="h5">
        All Submissions
      </Typography>
      <Table rawResults={rawResults} />
    </Grid>
  </Grid>
);

const BigNumbers = ({ rawResults }) => {
  const thisYear = useMemo(() => {
    if (rawResults) {
      return rawResults.filter(
        (f) => new Date(f.creationTime).getFullYear() === 2022
      ).length;
    }
  }, [rawResults]);

  const nonNull = useMemo(() => {
    if (rawResults) {
      return rawResults.filter((f) => !responseIsNull(f)).length;
    }
  }, [rawResults]);

  return (
    <Grid
      alignItems="stretch"
      container
      direction="row"
      justifyContent="space-between"
      spacing={5}
    >
      <Grid item xs={4}>
        <NumberCard label="Total Submissions" value={rawResults.length} />
      </Grid>
      <Grid item xs={4}>
        <NumberCard label="Non-Null Submissions" value={nonNull} />
      </Grid>
      <Grid item xs={4}>
        <NumberCard label="Submissions This Year" value={thisYear} />
      </Grid>
    </Grid>
  );
};

export default AdminView;

const NumberCard = ({ label, value }) => (
  <Card sx={{ display: "flex" }}>
    <CardHeader
      sx={{
        textAlign: "center",
      }}
      title={label}
      titleTypographyProps={{ variant: "h4" }}
    ></CardHeader>
    <CardContent
      sx={{
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.primary.light,
        color: "white",
        display: "flex",
      }}
    >
      <Typography align="center" variant="h4">
        {value || "N/A"}
      </Typography>
    </CardContent>
  </Card>
);

const SubmissionOverview = ({ rawResults }) => {
  const byDate = useMemo(() => {
    if (rawResults) {
      const grouped = rawResults.reduce((acc, curr) => {
        const key = new Date(curr.creationTime).toLocaleString().slice(3, 10);
        if (!acc[key]) {
          acc[key] = [curr];
        } else {
          acc[key].push(curr);
        }
        return acc;
      }, {});

      return Object.keys(grouped)
        .flatMap((key) => {
          return grouped[key].reduce(
            (acc, curr) => ({
              ...acc,
              key,
              Graduate:
                curr.Level === "Graduate Program"
                  ? acc["Graduate"]
                    ? acc["Graduate"] + 1
                    : 1
                  : acc["Graduate"] || 0,
              Undergraduate:
                curr.Level === "Undergraduate Program"
                  ? acc["Undergraduate"]
                    ? acc["Undergraduate"] + 1
                    : 1
                  : acc["Undergraduate"] || 0,
            }),
            {}
          );
        })
        .sort((a, b) =>
          new Date(a.key.replaceAll("/", "/01/")) <
          new Date(b.key.replaceAll("/", "/01/"))
            ? -1
            : 1
        );
    } else {
      return [];
    }
  }, [rawResults]);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={byDate}>
        <CartesianGrid />
        <XAxis dataKey="key" />
        <YAxis />
        <Tooltip />
        <Line fill={"red"} stroke={"red"} dataKey="Undergraduate" />
        <Line dataKey="Graduate" fill={"deepskyblue"} stroke={"deepskyblue"} />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

const Table = ({ rawResults }) => {
  const [search, setSearch] = useState("");
  const [filterNulls, setFilterNulls] = useState(false);

  const _displayValues = rawResults.map((row) => ({
    id: row.id,
    creationTime: row.creationTime,
    accountType: row.accountType,
    Level: row.Level,
    Faculty: row.Faculty,
    Year: row.Year,
    WrittenComplete: getEntries(row).filter(
      ([k, v]) => k.toString().startsWith("written") && v !== null
    ).length
      ? "Yes"
      : "No",
    OralComplete: getEntries(row).filter(
      ([k, v]) => k.toString().startsWith("oral") && v !== null
    ).length
      ? "Yes"
      : "No",
    VocabComplete: getEntries(row).filter(
      ([k, v]) => k.toString().startsWith("written_1.6_vocab") && v !== null
    ).length
      ? "Yes"
      : "No",
  }));

  const columns = useMemo(() => {
    if (rawResults) {
      return Object.keys(_displayValues[0]).map((r) => ({
        field: r,
        headerName: r,
        width: ["id", "Faculty"].includes(r) ? 200 : 100,
      }));
    } else {
      return [];
    }
  }, [rawResults]);

  const displayValues = useMemo(() => {
    const searched = (_displayValues || []).filter(
      (r) =>
        r.id.includes(search) ||
        r.Level?.toLowerCase().includes(search) ||
        r.Faculty?.toLowerCase().includes(search) ||
        r.Year?.toLowerCase().includes(search)
    );

    return filterNulls
      ? searched.filter(
          (f) =>
            f.OralComplete === "Yes" ||
            f.VocabComplete === "Yes" ||
            f.WrittenComplete === "Yes"
        )
      : searched;
  }, [search, filterNulls, rawResults]);

  const downloadCsv = useCallback(() => {
    const visibleIds = displayValues.map((d) => d.id);
    const downloadable = rawResults.filter((r) => visibleIds.includes(r.id));
    return exportCSV(downloadable, "export.csv");
  }, [displayValues, rawResults]);

  return (
    <Grid spacing={3} width="100%" direction="column" container>
      <Grid
        item
        container
        wrap="nowrap"
        direction="row"
        justifyContent={"space-between"}
      >
        <Grid alignItems="center" direction="row" spacing={2} item container>
          <Grid item>
            <TextField
              label="Search"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value || "")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox onChange={() => setFilterNulls(!filterNulls)} />
              }
              label="Filter nulls?"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={downloadCsv} variant="outlined">
            Export CSV
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <div style={{ height: 400, width: "100%" }}>
          {/* {columns && <DataGrid rows={displayValues} columns={columns} />} */}
        </div>
      </Grid>
    </Grid>
  );
};

/**
 * Convert given table to CSV and downloads it to user.
 */
const downloadCsv = (filename, blob) => {
  const downloadLink = document.createElement("a");
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = filename;
  downloadLink.click();
  URL.revokeObjectURL(url);
};

/**
 * Generates a table given the columnDefs and rowData, and downloads as CSV.
 */
export const exportCSV = (data, filename) => {
  const headers = Object.keys(data[0]).join(",") + "\r\n";
  const body = data.map((d) =>
    Object.values(d)
      .map((d) => `"${d}"`)
      .join(",")
  );
  const joined = body.join("\r\n");
  const blob = new Blob([headers + joined], {
    type: "text/csv;charset=utf-8",
  });
  downloadCsv(filename, blob);
};
