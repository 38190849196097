
export var WritingPostSurveyForm = {
    "formHeader": "Written Communication – Experience Survey & Certificate of Completion",
    "formType": 'writtenPostSurvey',
    "formText": {
        "0": "Now that you have completed the Written Communication Module, please complete the following questions based on what you have learned. Your responses will be anonymized and used only for research purposes. If you have any questions, please contact: aplusuoft.contact@gmail.com.",
    },
    "formSections": {
        "0": {
            "sectionHeader": "",
            "sectionText": {
            },
            "sectionQuestion": {
                "0": {
                    "qText": "1.a How well can you do the following in English in an academic setting (university or high school)? <br/><br/> English Reading",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Understand vocabulary and grammar when I read.", "Understand major ideas when I read.", "Understand how the ideas in a text relate to each other.", "Organize or outline the important ideas and concepts in texts.", "Compare and understand different viewpoints.", "Understand subject-specific vocabulary.", "Understand idiomatic language (e.g. a piece of cake)", "Understand culturally-specific views or ideas"],
                    "response": [null, null, null, null, null, null, null, null]
                },
                "1": {
                    "qText": "1.b Which of the following skills would you like to improve? Choose all that apply. ",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Understand vocabulary and grammar when I read.", "Understand major ideas when I read.", "Understand how the ideas in a text relate to each other.", "Organize or outline the important ideas and concepts in texts.", "Compare and understand different viewpoints.", "Understand subject-specific vocabulary.", "Understand idiomatic language (e.g. a piece of cake)", "Understand culturally-specific views or ideas"],
                    "response": [null, null, null, null, null, null, null, null]
                },
                "2": {
                    "qText": "2.a How well can you do the following in English in an academic setting (university or high school)? <br/><br/> English Writing",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Write a response to an assigned topic (e.g. essay, critical summary, reflection, report, etc.)", "Summarize and paraphrase in writing information that I have read in English.", "Summarize in writing information that I have listened to in English.", "Use correct grammar, vocabulary, spelling, and punctuation when I write in English."],
                    "response": [null, null, null, null]
                },
                "3": {
                    "qText": "2.b. Which of the following skills would you like to improve? Choose all that apply. ",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Writing a critical summary on an assigned topic (e.g. essay, critical summary, reflection, report, etc.)", "Summarizing and paraphrasing in writing information that I have read in English.", "Summarizing in writing information that I have listened to in English.", "Using correct grammar, vocabulary, spelling, and punctuation when I write in English."],
                    "response": [null, null, null, null]
                },
                "4": {
                    "qText": " 3. How helpful was the critical reflection task? ",
                    "qType": "mcq",
                    "options": ["Not at all helpful", "Not very helpful", "Helpful", "Very helpful", "Extremely helpful"],
                    "response": null
                },
                "5": {
                    "qText": "4. Add any additional comments or explanation here regarding how helpful the critical reflection was: ",
                    "qType": "text",
                    "options": [],
                    "response": null
                },
                "6": {
                    "qText": "5. How useful did you find the resources in the module?",
                    "qType": "mcq",
                    "options": ["Not at all useful", "Not very useful", "Useful", "Very useful", "Extremely useful"],
                    "response": null
                },
                "7": {
                    "qText": "6.  Add any additional comments or explanation here regarding resources:",
                    "qType": "text",
                    "options": [],
                    "response": null
                },
                "8": {
                    "qText": "7. Is there anything about written communication that you had hoped to learn but was not covered in this module? ",
                    "qType": "text",
                    "options": [],
                    "response": null
                },
                "9": {
                    "qText": "8. Are there any other suggestions that you would like to add? ",
                    "qType": "text",
                    "options": [],
                    "response": null
                }
            }
        },
        "1": {
                "sectionHeader": "Academic Literacy",
                "sectionText": {
                },
                "sectionQuestion": {
                    "0": {
                        "qText": "1. How well can you do the following in English (university or high school)?",
                        "qType": "table",
                        "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                        "options": ["Distinguish main ideas from examples.", "Distinguish primary sources from secondary ones. ", "Distinguish facts from opinions. ", "Identify the author's perspectives and stances. ", "Reflect on the author’s perspectives critically. ", "Paraphrase and use direct quotes.", "Identify biases in course materials (e.g. readings). ", "Form own opinions.", "Interpret information from diagrams, graphs, charts, or formulas."],
                        "response": [null, null, null, null, null, null, null, null, null]
                    },
                    "1": {
                        "qText": "Which of the following skills would you like to improve? Choose all that apply. ",
                        "qType": "singleRowTable",
                        "tableTitles": ["I would like to improve this skill"],
                        "options": ["Distinguish main ideas from examples.", "Distinguish primary sources from secondary ones. ", "Distinguish facts from opinions. ", "Identify the author's perspectives and stances. ", "Reflect on the author’s perspectives critically. ", "Paraphrase and use direct quotes.", "Identify biases in course materials (e.g. readings). ", "Form own opinions.", "Interpret information from diagrams, graphs, charts, or formulas."],
                        "response": [null, null, null, null, null, null, null, null, null]
                    },
                }
            },
    },
    "formAgreement": {
        "active": false,
        "textLine": "I agree/Agree/I consent",
        "response": false
    }
}

