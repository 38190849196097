import React from 'react';
import {TextField, Button, Grid, Typography} from '@material-ui/core';
import { Link } from 'react-router-dom';
// Backend Function
import {authenticatePassword} from '../../../service/password/dbService.js'

  
class ChangePassword extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            oldPass: '',
            newPass: '',
            newPass2: '',
            short: false,
        }
    }

    handleChange(event) {
        var id = event.currentTarget.id
        if (id === 'oldPass') {
            this.setState({ oldPass : event.target.value})
        } else if (id === 'newPass') {
            this.setState({ newPass : event.target.value})
        } else {
            this.setState({ newPass2 : event.target.value})
        }

        if (this.state.newPass.length < 5){
            this.setState({short: true})
        }
        else{
            this.setState({short: false})
        }
    }

    handleClick(event) {
        event.preventDefault();
        console.log(authenticatePassword(this.state.oldPass, this.state.newPass))
    }

    render() {
        
        return (
            <Grid container alignItems="center" spacing={3} justify="center"
            style={{ paddingLeft: "24%", paddingRight: "24%", paddingTop: "10%" }} >
                <Grid item xs={12}>
                    <Typography variant="h4" color="textSecondary">Please enter a new password</Typography>
                    <Typography variant="h6" color="textSecondary">If you have just signed up, please check your email for further instructions.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="oldPass" label="Old Password" type="password"  onChange={(event) => this.handleChange(event)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="newPass" label="New Password" type="password"  onChange={(event) => this.handleChange(event)}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="newPass2" label="Re-enter New Password" type="password" onChange={(event) => this.handleChange(event)}/>
                </Grid>
                <Grid item xs={12}>
                    
                        <Button variant="contained" disabled={this.state.newPass !== this.state.newPass2 || this.state.newPass2.length===0 || this.state.short} color="primary" onClick={(event) => this.handleClick(event)}>
                            <Link style={{ textDecoration: 'none', color:"#FFFFFF", padding:"0px" }} to='/onboarding'>{"Change Password"}</Link>
                        </Button>
                </Grid>
                {this.state.newPass !== this.state.newPass2 && (
                    <Grid item xs={12}>
                        <Typography color="secondary">New password does not match!</Typography>
                    </Grid>
                )}
                {this.state.short && (
                    <Grid item xs={12}>
                    <Typography color="secondary">Password must be at least 6 characters!</Typography>
                </Grid>
                )}
            </Grid>
        )
    
    };
}

export default ChangePassword;