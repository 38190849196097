import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {FirebaseRedux} from './FirestoreRedux.js';
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";


ReactDOM.render(
  <FirebaseRedux />,
  document.getElementById("root")
);

serviceWorker.unregister();
