import React from 'react'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import DashFrame from '../../components/DashFrame/DashFrame'
import { addUsersFromCSV } from '../../service/users/registration/dbService'
import { copyModule, addModuleToDb, updateModule, updateSpecific, printInfo, writeModuleToFile } from '../../components/Wrappers/ContentBuilder/dbHelpers'
import moduleData from "../../static/module/academicIntegrity/final"
import moduleData2 from "../../static/module/writing/final"
import { adminEmails } from '../../config/adminEmails'

export default function AddUsers() {
    const email = useSelector(state => state.firebase.auth.email)

    if (!adminEmails.includes(email)) return <h2>Unauthorized</h2>
    return (
        <DashFrame>
            <Button primary variant="outlined" onClick={e => { addUsersFromCSV() }}> Add Users From CSV</Button>
            <Button primary variant="outlined" onClick={e => { copyModule("AcademicIntegrity") }}> Copy Module</Button>
            <Button primary variant="outlined" onClick={e => { addModuleToDb(moduleData2, "WritingModule") }}> Add Module</Button>
            {/* Update Module button is buggy. If want to change module without affecting saved responses, use Update Specific instead*/}
            <Button primary variant="outlined" onClick={e => { updateModule(moduleData, "oral") }}> Update Module</Button>
            <Button primary variant="outlined" onClick={async (e) => { await updateSpecific(moduleData2, "WritingModule") }}> Update Specific</Button>
            <Button primary variant="outlined" onClick={async (e) => { await printInfo() }}> Print Info</Button>
            <Button primary variant="outlined" onClick={async (e) => { await writeModuleToFile("WritingModule") }}>Fetch Module</Button>
            <Button primary variant="outlined" onClick={e => { }}>Delete Module</Button>
        </DashFrame>
    )
}