import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Radio, TableRow, TableCell } from "@material-ui/core";

const AssessmentRow = ({ question, lightBg, handleResponse, index, selected, disable }) => {
  const [selectedValue, setSelectedValue] = React.useState(selected);
  const classes = useStyles({ lightBg });


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    handleResponse(index, event.target.value);
  };

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.question}>{question}</TableCell>
      <TableCell className={classes.radio}>
        <Radio
          disabled={disable}
          checked={selectedValue === "a"}
          onChange={handleChange}
          color="default"
          value="a"
          size="small"
        />
      </TableCell>
      <TableCell className={classes.radio}>
        <Radio
          disabled={disable}
          checked={selectedValue === "b"}
          onChange={handleChange}
          color="default"
          value="b"
          size="small"
        />
      </TableCell>
      <TableCell className={classes.radio}>
        <Radio
          disabled={disable}
          checked={selectedValue === "c"}
          onChange={handleChange}
          color="default"
          value="c"
          size="small"
        />
      </TableCell>
      <TableCell className={classes.radio}>
        <Radio
          disabled={disable}
          checked={selectedValue === "d"}
          onChange={handleChange}
          color="default"
          value="d"
          size="small"
        />
      </TableCell>
      <TableCell className={classes.radio}>
        <Radio
          disabled={disable}
          checked={selectedValue === "e"}
          onChange={handleChange}
          value="e"
          color="default"
          size="small"
        />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles({
  root: {
  },
  radio: {
    border: "solid",
    borderColor: "white",
    backgroundColor: (props) => (props.lightBg ? "#ffffff" : "#eeeeee"),
    borderWidth: "1px",
    textAlign: "center",
  },
  question: {
    border: "solid",
    borderColor: "white",
    backgroundColor: (props) => (props.lightBg ? "#ffffff" : "#eeeeee"),
    borderWidth: "1px",
    padding: "1rem",
  },
});

export default AssessmentRow;
