import { Container, Typography } from "@material-ui/core";
import React from "react";
import DashFrame from "../../../components/DashFrame/DashFrame";
import Report from "../../../components/Util/Report/SimpleDashboard";

const ReportPage = () => {
  return (
    <DashFrame>
      <Container>
        <Report />
      </Container>
    </DashFrame>
  );
};

export default ReportPage;
