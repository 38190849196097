import React, { Component } from "react";
import "./Interface.css";
import Input from "../input/Input"
import Output from "../output/Output"
import Button from "react-bootstrap/Button";


class AsrInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio_file: "",
      text: "",
      loading: false,
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.clearText = this.clearText.bind(this);
  };

  handleUpload(audio, text) {
    this.setState(prevState => ({
      audio_file: audio,
      text: prevState.text + " " + text,
      loading: false,
    }))
  };

  clearText() {
    this.setState({
      audio_file: "",
      text: "",
    })
  }

  startLoading() {
    this.setState({
      audio_file: "",
      text: "",
      loading: true,
    })
  }



  render() {
    return (
      <div>
        <div className="Interface">
          <div className="Card">
            <Input visual={true} clearText={() => this.clearText()} handleUpload={(audio, text) => this.handleUpload(audio, text)} startLoading={() => this.startLoading()} />
          </div>
          <div className="Card">
            <Output audio_file={this.state.audio_file} text={this.state.text} loading={this.state.loading}/>
          </div>



        </div>

        <Button
                        style={{
                          background: "#7270a1",
                          "borderColor": "rgba(0, 32, 78)",
                          "marginright": "10px",}}
                        onClick={() => null}>
        Submit</Button>


      </div>


    )
  }
}
export default AsrInterface