import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';


class RichTextEditor extends React.Component {
    

    render() {
        return (
            <div className="quiz-card-container">
                <div key={this.props.questionId}>
                    {/* <div>
                        <h6>
                            Question
                            <span> {this.props.questionId + 1} </span>
                            of
                             <span> {this.props.totalLength}</span>
                        </h6>
                    </div> */}
                    <h6 className="quiz-question">{this.props.question.split("<ind>").map((text, idx) => 
                        {
                            
                            if (idx % 2 === 1)
                                return (<div style={{textIndent: '-3rem', marginLeft: '3rem'}}>{text.split("\n").map((item)=> (<p dangerouslySetInnerHTML={{ __html: item }} />))}</div>)
                            return (<span>{text.split("\n").map((item)=> (<p dangerouslySetInnerHTML={{ __html: item }} />))}</span>)
                        })
                    }</h6>

                    <div className="text-container">
                        <TextField
                        fullWidth
                            id="textEditor"
                            defaultValue={this.props.answer}
                            label="Your answer"
                            onChange={(e)=>this.props.onChange(e)}
                            multiline
                            rows={5}
                            rowsMax={15}
                            variant="outlined"
                            disabled={this.props.disable}
                        />
                        {!this.props.hideWordCount && <Typography style={{marginTop: '1rem'}}>Word Count: {this.props.wordCount}</Typography>}
                    </div>
                </div>

            </div>

        );
    }
}

export default RichTextEditor;
