import React from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import OralResources from "../../../components/Wrappers/Docs/OralResources";
import '../../../CSS_share.css'

function OralResourcePage( props ) {
    return (
        <DashFrame>
            <OralResources/>
        </DashFrame>
    );
}
export default OralResourcePage;