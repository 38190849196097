export var demographicForm = {
    "formHeader": "About-You Questionnaire \n",
    "formType": 'demographic',
    "formText": {
        "0": "In this Questionnaire, we would like to ask you about your background, language skills, current understanding of academic integrity, and learning needs. Your responses will be anonymized and used only for research purposes if you provided consent on the previous page. If you have any questions, please contact us at: <a href = 'mailto: aplusuoft.contact@gmail.com'>aplusuoft.contact@gmail.com</a>",
    },
    "formSections": {
        "0": {
            "sectionHeader": "Your Background",
            "sectionText": {},
            "sectionQuestion": {
                "0": {
                    "qText": "1. I am in a(n)",
                    "qType": "mcq",
                    "options": ["Undergraduate Program", "Graduate Program"],
                    "response": null
                },
                "1": {
                    "qText": "\n \n2. I am registered in",
                    "qType": "mcq",
                    "options": ["Faculty of Applied Science & Engineering","Faculty of Architecture, Landscape and Design", "Faculty of Arts & Science", "Faculty of Kinesiology", "Faculty of Music", "OISE", "University of Toronto Mississauga", "University of Toronto Scarborough (UTSC)"],
                    "response": null,
                    "other": null
                },
                "2": {
                    "qText": "If you selected OISE above, please select your department",
                    "qType": "mcq",
                    "options": ["Applied Psychology & Human Development", "Curriculum, Teaching, and Learning", "Leadership, Higher & Adult Education", "Social Justice Education"],
                    "response": null,
                    "optional": true,
                    "other": null
                },
                "3": {
                    "qText": "\n \n3. What is your major, specialist, or program of study?",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "other": null
                },
                "4": {
                    "qText": "\n \n 4a. Which degree are you pursuing?",
                    "qType": "mcq",
                    "options": ["Bachelor", "MA", "MEd", "MT", "EdD", "PhD", "Other"],
                    "response": null,
                    "other": null
                },
                "5": {
                    "qText": "\n \n 4b. If you selected 'Other' for question 4, please specify which degree you are pursuing:",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "optional": true,
                    "other": null
                },
                "6": {
                    "qText": "\n \n 5. Which year of this degree are you in?",
                    "qType": "mcq",
                    "options": ["Have not started yet, but will start soon", "Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6 or above"],
                    "response": null,
                    "other": null
                },
                "7": {
                    "qText": "\n \n 6. What is your gender?",
                    "qType": "mcq",
                    "options": ["Female", "Male", "Non-binary", "Prefer not to disclose"],
                    "response": null,
                    "other": null
                },
                "8": {
                    "qText": "\n \n 7. In which country or region were you born?",
                    "qType": "select",
                    "options": ["Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla",
                    "Antarctica", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas",
                    "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia",
                    "Bosnia and Herzegowina", "Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory", "Brunei Darussalam",
                    "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands",
                    "Central African Republic", "Chad", "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", "Colombia",
                    "Comoros", "Congo", "Congo, the Democratic Republic of the", "Cook Islands", "Costa Rica", "Cote d'Ivoire",
                    "Croatia (Hrvatska)", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
                    "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia",
                    "Falkland Islands (Malvinas)", "Faroe Islands", "Fiji", "Finland", "France", "France Metropolitan", "French Guiana",
                    "French Polynesia", "French Southern Territories", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar",
                    "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
                    "Heard and Mc Donald Islands", "Holy See (Vatican City State)", "Honduras", "Hong Kong", "Hungary", "Iceland", "India",
                    "Indonesia", "Iran (Islamic Republic of)", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
                    "Kazakhstan", "Kenya", "Kiribati", "Korea, Democratic People's Republic of", "Korea, Republic of", "Kuwait",
                    "Kyrgyzstan", "Lao, People's Democratic Republic", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libyan Arab Jamahiriya",
                    "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia, The Former Yugoslav Republic of", "Madagascar",
                    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", "Mauritius",
                    "Mayotte", "Mexico", "Micronesia, Federated States of", "Moldova, Republic of", "Monaco", "Mongolia", "Montserrat",
                    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "Netherlands Antilles",
                    "New Caledonia", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands",
                    "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn",
                    "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania", "Russian Federation", "Rwanda",
                    "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
                    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Seychelles", "Sierra Leone", "Singapore",
                    "Slovakia (Slovak Republic)", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
                    "South Georgia and the South Sandwich Islands", "Spain", "Sri Lanka", "St. Helena", "St. Pierre and Miquelon",
                    "Sudan", "Suriname", "Svalbard and Jan Mayen Islands", "Swaziland", "Sweden", "Switzerland", "Syrian Arab Republic",
                    "Taiwan", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Togo", "Tokelau", "Tonga",
                    "Trinidad and Tobago", "Tunisia", "Türkiye", "Turkmenistan", "Turks and Caicos Islands", "Tuvalu", "Uganda", "Ukraine",
                    "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay",
                    "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Virgin Islands (British)", "Virgin Islands (U.S.)",
                    "Wallis and Futuna Islands", "Western Sahara", "Yemen", "Yugoslavia", "Zambia", "Zimbabwe" ],
                    "response": null,
                },
                "9": {
                    "qText": "\n \n 8a. How long have you resided in Canada? (If you have not arrived in Canada, please put 0 year 0 month)? Please specify the number of YEARS:",
                    "qType": "select",
                    "options": ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80'],
                    "response": null,
                    "other": null
                },
                "10": {
                    "qText": "\n \n 8b. Please specify the number of MONTHS:",
                    "qType": "select",
                    "options": ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
                    "response": null,
                    "other": null
                },
                "11": {
                    "qText": "\n \n 9a. For university admission, did you submit a proof of your English language proficiency?",
                    "qType": "mcq",
                    "options": ["No, I was not required to submit any.", "Yes, I submitted an English test score (for example, TOEFL, IELTS)", "Yes, I completed an English course(s).", "Other"],
                    "response": null,
                    "other": null
                },
                "12": {
                    "qText": "\n \n 9b. If you selected 'Other' for question 9, please specify  what you submitted or will be submitting for proof of your English language proficiency:",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "optional": true,
                    "other": null
                },
            }
        },
        "1": {
            "sectionHeader": "\n \n Language Skills",
            "sectionText": {
            },
            "sectionQuestion": {
                "0": {
                    "qText": "\n \n 1. What is your primary language (the language you feel the most competent in and comfortable with)?",
                    "qType": "select",
                    "options": ['Algerian Arabic','Amharic','Assamese','Bavarian','Bengali','Bhojpuri','Burmese','Cantonese', 'Cebuano','Chhattisgarhi','Chittagonian','Czech','Deccan','Dutch','Eastern Punjabi','Egyptian Arabic','English','French','Gan','German','Greek','Gujarati','Hakka','Hausa','Hejazi Arabic','Hindi','Hungarian','Igbo','Indonesian','Iranian Persian','Italian','Japanese','Javanese','Jin Chinese','Kannada','Kazakh','Khmer','Kinyarwanda','Korean','Magahi','Maithili','Malay (Malaysian Malay)','Malayalam','Mandarin','Marathi','Mesopotamian Arabic','Min Bei Chinese','Min Dong Chinese','Min Nan Chinese','Moroccan Arabic','Nepali','Nigerian Fulfulde','North Levantine Arabic','Northern Kurdish','Northern Pashto','Northern Uzbek','Odia','Polish','Portuguese','Romanian','Rundi','Russian','Saʽidi Arabic','Sanaani Spoken Arabic','Saraiki','Sindhi','Sinhalese','Somali','South Azerbaijani','South Levantine Arabic','Southern Pashto','Spanish,Sudanese Arabic','Sunda','Sylheti','Tagalog','Taʽizzi-Adeni Arabic','Tamil','Telugu','Thai','Tunisian Arabic','Turkish','Ukrainian','Urdu','Uyghur','Vietnamese','Western Punjabi','Wu Chinese','Xiang Chinese','Yoruba','Yue Chinese','Zulu','Other'],
                    "response": null
                },
                "1": {
                    "qText": "2. Do you speak other languages? If so, please indicate up to three languages you speak. If English was not indicated as your primary language above, please include English here. <br/><br/>2a. Indicate your second language: (if applicable)",
                    "qType": "select",
                    "options": ['Algerian Arabic','Amharic','Assamese','Bavarian','Bengali','Bhojpuri','Burmese','Cantonese', 'Cebuano','Chhattisgarhi','Chittagonian','Czech','Deccan','Dutch','Eastern Punjabi','Egyptian Arabic','English','French','Gan','German','Greek','Gujarati','Hakka','Hausa','Hejazi Arabic','Hindi','Hungarian','Igbo','Indonesian','Iranian Persian','Italian','Japanese','Javanese','Jin Chinese','Kannada','Kazakh','Khmer','Kinyarwanda','Korean','Magahi','Maithili','Malay (Malaysian Malay)','Malayalam','Mandarin','Marathi','Mesopotamian Arabic','Min Bei Chinese','Min Dong Chinese','Min Nan Chinese','Moroccan Arabic','Nepali','Nigerian Fulfulde','North Levantine Arabic','Northern Kurdish','Northern Pashto','Northern Uzbek','Odia','Polish','Portuguese','Romanian','Rundi','Russian','Saʽidi Arabic','Sanaani Spoken Arabic','Saraiki','Sindhi','Sinhalese','Somali','South Azerbaijani','South Levantine Arabic','Southern Pashto','Spanish,Sudanese Arabic','Sunda','Sylheti','Tagalog','Taʽizzi-Adeni Arabic','Tamil','Telugu','Thai','Tunisian Arabic','Turkish','Ukrainian','Urdu','Uyghur','Vietnamese','Western Punjabi','Wu Chinese','Xiang Chinese','Yoruba','Yue Chinese','Zulu','Other'],
                    "response": null
                },
                "2": {
                    "qText": "2b. Indicate your third language: (if applicable)",
                    "qType": "select",
                    "options": ['Algerian Arabic','Amharic','Assamese','Bavarian','Bengali','Bhojpuri','Burmese', 'Cantonese', 'Cebuano','Chhattisgarhi','Chittagonian','Czech','Deccan','Dutch','Eastern Punjabi','Egyptian Arabic','English','French','Gan','German','Greek','Gujarati','Hakka','Hausa','Hejazi Arabic','Hindi','Hungarian','Igbo','Indonesian','Iranian Persian','Italian','Japanese','Javanese','Jin Chinese','Kannada','Kazakh','Khmer','Kinyarwanda','Korean','Magahi','Maithili','Malay (Malaysian Malay)','Malayalam','Mandarin','Marathi','Mesopotamian Arabic','Min Bei Chinese','Min Dong Chinese','Min Nan Chinese','Moroccan Arabic','Nepali','Nigerian Fulfulde','North Levantine Arabic','Northern Kurdish','Northern Pashto','Northern Uzbek','Odia','Polish','Portuguese','Romanian','Rundi','Russian','Saʽidi Arabic','Sanaani Spoken Arabic','Saraiki','Sindhi','Sinhalese','Somali','South Azerbaijani','South Levantine Arabic','Southern Pashto','Spanish,Sudanese Arabic','Sunda','Sylheti','Tagalog','Taʽizzi-Adeni Arabic','Tamil','Telugu','Thai','Tunisian Arabic','Turkish','Ukrainian','Urdu','Uyghur','Vietnamese','Western Punjabi','Wu Chinese','Xiang Chinese','Yoruba','Yue Chinese','Zulu','Other'],
                    "response": null
                },
                "3": {
                    "qText": "\n \n 2c. Indicate your fourth language: (if applicable)",
                    "qType": "select",
                    "options": ['Algerian Arabic','Amharic','Assamese','Bavarian','Bengali','Bhojpuri','Burmese', 'Cantonese', 'Cebuano','Chhattisgarhi','Chittagonian','Czech','Deccan','Dutch','Eastern Punjabi','Egyptian Arabic','English','French','Gan','German','Greek','Gujarati','Hakka','Hausa','Hejazi Arabic','Hindi','Hungarian','Igbo','Indonesian','Iranian Persian','Italian','Japanese','Javanese','Jin Chinese','Kannada','Kazakh','Khmer','Kinyarwanda','Korean','Magahi','Maithili','Malay (Malaysian Malay)','Malayalam','Mandarin','Marathi','Mesopotamian Arabic','Min Bei Chinese','Min Dong Chinese','Min Nan Chinese','Moroccan Arabic','Nepali','Nigerian Fulfulde','North Levantine Arabic','Northern Kurdish','Northern Pashto','Northern Uzbek','Odia','Polish','Portuguese','Romanian','Rundi','Russian','Saʽidi Arabic','Sanaani Spoken Arabic','Saraiki','Sindhi','Sinhalese','Somali','South Azerbaijani','South Levantine Arabic','Southern Pashto','Spanish,Sudanese Arabic','Sunda','Sylheti','Tagalog','Taʽizzi-Adeni Arabic','Tamil','Telugu','Thai','Tunisian Arabic','Turkish','Ukrainian','Urdu','Uyghur','Vietnamese','Western Punjabi','Wu Chinese','Xiang Chinese','Yoruba','Yue Chinese','Zulu','Other'],
                    "response": null
                },
                "4": {
                    "qText": "For the following questions, please rate yourself to the best of your ability. <br/><br/>3a. How well can you do the following in English in an academic setting (university or high school)? <br/><br/> English Listening:",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Understand the main ideas of lectures and conversations.", "Understand important facts and details of lectures and conversations.", "Understand the relationships among ideas in a lecture or conversation.", "Understand a speaker's attitude or opinion about what he or she is saying.", "Recognize why a speaker is saying something."],
                    "response": [null, null, null, null, null]
                },
                "5": {
                    "qText": "\n \n 3b. \n \n Which of the following skills would you like to improve? Choose all that apply.",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Understanding the main ideas of lectures and conversations.", "Understanding important facts and details of lectures and conversations.", "Understanding the relationships among ideas in a lecture conversation.", "Understanding a speaker's attitude or opinion about what he or she is saying.", "Recognizing why a speaker is saying something."],
                    "response": [null, null, null, null, null]
                },
                "6": {
                    "qText": "\n \n 4a. How well can you do the following in English in an academic setting (university or high school)? <br/><br/>English Reading:",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Understand vocabulary and grammar when I read.", "Understand major ideas when I read.", "Understand how the ideas in a text relate to each other.", "Understand the relative importance of ideas when I read.", "Organize or outline the important ideas and concepts in texts."],
                    "response": [null, null, null, null, null]
                },
                "7": {
                    "qText": "\n \n 4b. Which of the following skills would you like to improve? Choose all that apply.",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Understanding vocabulary and grammar when I read.", "Understanding major ideas when I read.", "Understanding how the ideas in a text relate to each other.", "Understanding the relative importance of ideas when I read.", "Organizing or outlining the important ideas and concepts in texts."],
                    "response": [null, null, null, null, null]
                },
                "8": {
                    "qText": "\n \n 5a. How well can you do the following in English in an academic setting (university or high school)? <br/><br/>English Speaking:",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Speak for about one minute in response to a question.", "When I speak in English, other people can understand me.", "Participate in conversations or discussions in English. ", "Orally summarize information from a talk I have listened to in English.", "Orally summarize information that I have read in English."],
                    "response": [null, null, null, null, null]
                },
                "9": {
                    "qText": "\n \n 5b. Which of the following skills would you like to improve? Choose all that apply.",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Speaking for about one minute in response to a question.", "When I speak in English, other people can understand me.", "Participating in conversations or discussions in English.", "Orally summarizing information from a talk I have listened to in English.", "Orally summarizing information that I have read in English."],
                    "response": [null, null, null, null, null]
                },
                "10": {
                    "qText": "\n \n 6a. How well can you do the following in English in an academic setting (university or high school)?  <br/><br/>English Writing:",
                    "qType": "table",
                    "tableTitles": ["Very poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Write an essay in class on an assigned topic.", "Summarize and paraphrase in writing information that I have read in English.", "Summarize in writing information that I have listened to in English.", "Use correct grammar, vocabulary, spelling, and punctuation when I write in English."],
                    "response": [null, null, null, null]
                },
                "11": {
                    "qText": "\n \n 6b. Which of the following skills would you like to improve? Choose all that apply.",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Writing an essay in class on an assigned topic.", "Summarizing and paraphrasing in writing information that I have read in English.", "Summarizing in writing information that I have listened to in English.", "Using correct grammar, vocabulary, spelling, and punctuation when I write in English."],
                    "response": [null, null, null, null]
                },
            }
        },
        "2": {
            "sectionHeader": "<br/>Academic Literacy",
            "sectionText": {
            },
            "sectionQuestion": {
                "0": { 
                    "qText": "1. How well can you do the following in English (university or high school)?",
                    "qType": "table",
                    "tableTitles": ["Very Poor", "Poor", "Fair", "Good", "Very Good"],
                    "options": ["Distinguish main ideas from examples.", "Distinguish primary sources from secondary ones. ", "Distinguish facts from opinions.", "Identify the author’s perspectives and stances.", "Reflect on authors’ perspectives critically.",
                    "Paraphrase and use direct quotes.", "Identify biases in course materials (e.g. readings).", "Form own opinions.", "Interpret information from diagrams, graphs, charts, or formulas."],
                    "response": [null, null, null, null, null, null, null, null, null]
                },
                "1": {
                    "qText": "2. Which of the following skills would you like to improve? Choose all that apply.",
                    "qType": "singleRowTable",
                    "tableTitles": ["I would like to improve this skill"],
                    "options": ["Distinguish main ideas from examples.", "Distinguish primary sources from secondary ones. ", "Distinguish facts from opinions.", "Identify the author’s perspectives and stances.", "Reflect on authors’ perspectives critically.",
                    "Paraphrase and use direct quotes.", "Identify biases in course materials (e.g. readings).", "Form own opinions.", "Interpret information from diagrams, graphs, charts, or formulas."],
                    "response": [null, null, null, null, null, null, null, null]
                },
            }
        },
        "3": {
            "sectionHeader": "<br/>Academic Integrity",
            "sectionText": {
            },
            "sectionQuestion": {
                
                "0": {
                    "qText": "1. How  well do you understand the concept of “academic integrity”?",
                    "qType": "mcq",
                    "options": ["Extremely well", "Very well", "Well", "Not very well (I have heard of the term, but do not know much about it.)", "Not at all (I have not heard of the term.)"],
                    "response": null,
                },
                "1": { 
                    "qText": "2. In academia, we use distinct communication rules and styles. Please indicate your level of awareness of the following:",
                    "qType": "table",
                    "tableTitles": ["Very low", "Below average", "Average", "Above average", "Very high"],
                    "options": ["APA style", "MLA style", "Chicago style"],
                    "response": [null, null, null]
                },
                "2": {
                    "qText": "3. It is important to acknowledge and give credits to the sources of information. Please indicate your level of awareness of the following:",
                    "qType": "table",
                    "tableTitles": ["Very low", "Below average", "Average", "Above average", "Very high"],
                    "options": ["Citations", "Credibility of sources ", "Plagiarism", "Collaborative group work"],
                    "response": [null, null, null, null]
                },
            }
        },
        "4": {
            "sectionHeader": "<br/> Learning Goals",
            "sectionText": ["1. Think about the different types of academic communication discussed in the survey. What are the top three areas on which you would like to focus?"],
            "sectionQuestion": {
                "0": {
                    "qText": "a.",
                    "qType": "text",
                    "response": null
                },
                "1": {
                    "qText": "b.",
                    "qType": "text",
                    "response": null
                },
                "2": {
                    "qText": "c.",
                    "qType": "text",
                    "response": null
                },
            }
        },
    },

    
    "formAgreement": {
        "active": false,
        "textLine": "I agree/Agree/I consent",
        "response": null
    }
}
