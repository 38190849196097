import React, { Component } from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import { connect } from "react-redux";
import ModuleWindow from '../../../components/Wrappers/ContentView/ModuleWindow'
import '../../../CSS_share.css'

class OralBuildingPage extends Component {

    render(){
        return( 
        <DashFrame>
            <ModuleWindow uid={this.props.auth.uid} moduleType="oral"/>
        </DashFrame>
        );
        
    }
}
const mapStateTOProps = (state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    };
};
  
export default connect(mapStateTOProps)(OralBuildingPage);