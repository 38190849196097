import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Typography, Grid, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  cell: {
    height: "100%",
  },
  card: {
    display: "flex",
    padding: "2rem",
    textAlign: "center",
    marginBottom: "2rem",
    alignItems: "center",
    background: "#42425D",
    color: "white",
  },
  cardDesc: {
    padding: "2rem",
    marginLeft: "2rem",
    marginBottom: "2rem",
    backgroundImage: "linear-gradient(to right, #dfdfdf , white)",
  },
  container: {},
  row: {
    "& + &": {
      marginTop: "3rem",
    },
  },
});
class AcademicIntegrityResources extends Component {
  createHeading(parentElement, headingType, headingContent) {
    var heading = document.createElement(headingType);
    heading.innerHTML = headingContent;
    parentElement.appendChild(heading);
  }

  createText(parentElement, textContent) {
    var text = document.createElement("p");
    text.innerHTML = textContent;
    parentElement.appendChild(text);
  }

  renderDocContent(doc) {
    var docItems = Object.entries(doc[1]);
    var docContent = document.getElementById("docs-content");
    if (docContent) {
      docContent.innerHTML = "";
      // Create Heading
      this.createHeading(docContent, "h4", doc[0]);
      // Insert Doc items
      docItems.map((item, index) => {
        if (item[0].search("inner") === -1) {
          this.createHeading(docContent, "h6", item[0]);
          docContent.appendChild(document.createElement("hr"));
        }
        this.createText(docContent, item[1].text);
        return 0;
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Container style={{ paddingTop: "40px" }}>
          <Typography variant="h4">Resources</Typography>
          <hr /> <br></br>
          <Grid container alignItems="stretch">
            <Grid item xs={3} component={Card} className={classes.card}>
              <Typography variant="h6">
                International Center for Academic Integrity
              </Typography>
            </Grid>
            <Grid item xs={7} component={Card} className={classes.cardDesc}>
              <i>
                <p>
                  The University of Toronto recognizes the definition of
                  academic integrity provided by the{" "}
                  <a
                    style={{ padding: "0px" }}
                    href="https://academicintegrity.org/"
                  >
                    International Centre for Academic Integrity
                  </a>{" "}
                  :{" "}
                </p>
                <p style={{ marginLeft: "30px", marginRight: "50px" }}>
                  The International Center for Academic Integrity defines
                  academic integrity as a commitment, even in the face of
                  adversity, to six fundamental values: honesty, trust,
                  fairness, respect, responsibility, and courage. From these
                  values flow principles of behavior that enable academic
                  communities to translate ideals to action.{" "}
                </p>
              </i>
            </Grid>
            <Grid item xs={3} component={Card} className={classes.card}>
              <Typography variant="h6">Faculty of Arts and Science</Typography>
            </Grid>
            <Grid item xs={7} component={Card} className={classes.cardDesc}>
              <p>
                At the University of Toronto,{" "}
                <a
                  style={{ padding: "0px" }}
                  href="https://www.artsci.utoronto.ca/current/academic-advising-and-support/student-academic-integrity"
                >
                  the Faculty of Arts and Science
                </a>{" "}
                recognizes the definition above and describes the University of
                Toronto as a place where:{" "}
              </p>
              <ul>
                <li>Competition is fair and honest work is rewarded </li>
                <li>
                  Students are developing the personal and professional skills
                  needed for success, like time management, effective
                  research/writing, independent thinking and ethical judgement
                </li>
                <li>Intellectual property is respected</li>
                <li>
                  Our degrees continue to receive the respect and recognition
                  they deserve
                </li>
              </ul>
            </Grid>
            <Grid item xs={3} component={Card} className={classes.card}>
              <Typography variant="h6">
                Code of Behaviour on Academic Matters
              </Typography>
            </Grid>
            <Grid item xs={7} component={Card} className={classes.cardDesc}>
              <p>
                At the University of Toronto,{" "}
                <a
                  style={{ padding: "0px" }}
                  href="https://governingcouncil.utoronto.ca/secretariat/policies/code-behaviour-academic-matters-july-1-2019"
                >
                  the Code of Behaviour on Academic Matters
                </a>{" "}
                is the university’s official policy regarding academic matters
                and outlines the standards and processes for students and
                faculty when the values of academic integrity are not upheld.{" "}
              </p>
            </Grid>
            <Grid item xs={3} component={Card} className={classes.card}>
              <Typography variant="h6">
                Online Resources for Academic Style
              </Typography>
            </Grid>
            <Grid item xs={7} component={Card} className={classes.cardDesc}>
              <p>
                Online resources for academic style (e.g., MLA, APA, Chicago)
                with a focus on citation. There are links to resources outside
                the University of Toronto.{" "}
              </p>
              <ul>
                <li>
                  UofT Writing Advice:{" "}
                  <a
                    style={{ padding: "0px" }}
                    href="https://advice.writing.utoronto.ca/using-sources/"
                  >
                    {" "}
                    https://advice.writing.utoronto.ca/using-sources/{" "}
                  </a>
                </li>
                <li>
                  UofT Libraries:{" "}
                  <a
                    style={{ padding: "0px" }}
                    href="https://guides.library.utoronto.ca/citing "
                  >
                    https://guides.library.utoronto.ca/citing{" "}
                  </a>
                </li>
                <li>
                  Sidney Smith Commons: 
                  <a
                    style={{ padding: "0px" }}
                    href="https://sidneysmithcommons.artsci.utoronto.ca/how-can-i-cite-properly/"
                  >
                    https://sidneysmithcommons.artsci.utoronto.ca/how-can-i-cite-properly/
                  </a>{" "}
                </li>
              </ul>
            </Grid>
            <Grid item xs={3} component={Card} className={classes.card}>
              <Typography variant="h6">
                Attending Workshops, Individual Appointments and Other Smart
                Strategies
              </Typography>
            </Grid>
            <Grid item xs={7} component={Card} className={classes.cardDesc}>
              <p>
                <b>Academic Success Centre </b>
              </p>
              <p>
                Students can attend workshops or book individual appointments
                with a learning strategist or mentors. Link: 
                <a
                  style={{ padding: "0px" }}
                  href="https://studentlife.utoronto.ca/department/academic-success/"
                >
                  https://studentlife.utoronto.ca/department/academic-success/
                </a>{" "}
              </p>
              <p>
                <b>Graduate Centre for Communication (GCAC) </b>
              </p>
              <p>
                Workshops for students related to research paper and article
                writing and more. Link: 
                <a
                  style={{ padding: "0px" }}
                  href="https://www.sgs.utoronto.ca/resources-supports/gcac/workshops/"
                >
                  {" "}
                  https://www.sgs.utoronto.ca/resources-supports/gcac/workshops/{" "}
                </a>{" "}
              </p>
              <p>
                <b> OISE Student Success Centre (OSSC) </b>
              </p>
              <p>
                Students can book appointments with advisors who provide support
                for academic writing, English language development, presentation
                skills, mock interviews, etc. Link: 
                <a
                  style={{ padding: "0px" }}
                  href="https://www.artsci.utoronto.ca/current/academic-advising-and-support/student-academic-integrity"
                >
                  {" "}
                  https://www.artsci.utoronto.ca/current/academic-advising-and-support/student-academic-integrity{" "}
                </a>{" "}
              </p>

              <p>
                <b>Smart strategies to keep academic integrity </b>
              </p>
              <p>
                Information and strategies to help you when you do group work,
                cite or quote the work of others, take exams, and much more.
                Link:{" "}
                <a
                  style={{ padding: "0px" }}
                  href="https://www.academicintegrity.utoronto.ca/smart-strategies/  "
                >
                  https://www.academicintegrity.utoronto.ca/smart-strategies/{" "}
                </a>
              </p>
            </Grid>
          </Grid>
        </Container>

        <Container style={{ "padding-top": "40px" }}>
          <Typography></Typography>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(AcademicIntegrityResources);
