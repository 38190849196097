import React, { Component } from "react";
import { ReactMic } from "react-mic";
import "./Record.css";
import { storage, auth, functions } from "../../../../FirestoreRedux.js";
import firebase from "firebase";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class Record extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blobURL: null,
      isRecording: false,
      isPaused: false,
      blobObj: null,
      submitted: false,
      firstTry: true,
      popupOpen: false,
      isFeedbackOpen: false,
    };
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);

    this.popupOpen = this.popupOpen.bind(this);
    this.popupClose = this.popupClose.bind(this);

    this.setOpenFeedback = this.setOpenFeedback.bind(this);
    this.setCloseFeedback = this.setCloseFeedback.bind(this);

    this.onStop = this.onStop.bind(this);
    this.uploadBlob = this.uploadBlob.bind(this);
  }

  startRecording() {
    this.setState({ isRecording: true });
  }

  stopRecording() {
    this.setState({ isRecording: false });
  }

  setOpenFeedback() {
    this.setState({ isFeedbackOpen: true });
  }

  setCloseFeedback() {
    this.setState({ isFeedbackOpen: false });
  }

  onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob);
  }

  onStop(blobObject) {
    this.setState({
      blobURL: blobObject.blobURL,
      blobObj: blobObject.blob,
    });
    console.log("blobObject is: ", blobObject);
    if (this.props.uploadOnStop) this.uploadBlob(true);
  }

  // uploadBlob() {
  //   const req = new XMLHttpRequest();
  //   const formData = new FormData();
  //   formData.append("file", this.state.blobObj, "recorded_audio.wav");
  //   req.open("POST", "http://localhost:5000/convert_and_parse");
  //   req.send(formData);
  //   this.props.startLoading();
  //   req.onload = () => this.props.handleUpload("recorded_audio.wav", JSON.parse(req.response)["text"]);
  // }

  uploadBlob(hidePopup) {
    var storageRef = storage.ref();
    var blobId = this.state.blobURL.substring(
      this.state.blobURL.lastIndexOf("/") + 1
    );
    var audioFileRef = storageRef.child(
      (this.props.saveLocation || "audio_files/") +
        auth.currentUser.uid +
        "/" +
        blobId +
        ".wav"
    );

    this.setState({ submitted: true, popupOpen: hidePopup === undefined });
    console.log("audioFileRef.name is ", audioFileRef.name);
    console.log("audioFileRef.fullPath is ", audioFileRef.fullPath);

    var uploadTask = audioFileRef.put(this.state.blobObj);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, paus, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("Upload unsuccessful: ", error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at ", downloadURL);
          if (this.props.submitRequired) alert("Submission successful!");
        });

        const speechToText = functions.httpsCallable("speechToText");
        speechToText({ audioBlobId: blobId })
          .then((result) => {
            console.log(
              "transcription file write error: ",
              result.data.writeErr
            );
            console.log("transcription: ", result.data.transcription);

            if (this.props.visual) {
              this.props.handleUpload(
                blobId + ".wav",
                result.data.transcription
              );
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    );
  }

  popupOpen() {
    this.setState({
      popupOpen: true,
    });
  }
  popupClose() {
    this.setState({
      popupOpen: false,
    });
  }

  render() {
    return (
      <div className="record">
        <br />
        <br />
        <ReactMic
          className="oscilloscope"
          record={this.state.isRecording}
          backgroundColor="white"
          visualSetting="sinewave"
          // audioBitsPerSecond= {16000}
          onStop={this.onStop}
          onSave={this.onSave}
          onData={this.onData}
          width="300"
          // mimeType="audio/wav"
          strokeColor="#000000"
        />
        <div>
          {this.state.blobURL && (
            <audio
              ref="audioSource"
              controls="controls"
              controlsList="nodownload"
              src={this.state.blobURL}
            />
          )}
        </div>

        {!this.state.isRecording && !this.state.blobURL ? (
          <button
            className="btn btn-info"
            disabled={this.state.isRecording}
            onClick={this.startRecording}
          >
            {" "}
            Record
            {/* <img alt="Record" className="Icon" src="baseline-mic-24px.svg" /> */}
          </button>
        ) : this.state.blobURL ? null : (
          <button
            className="btn btn-info"
            disabled={!this.state.isRecording}
            onClick={this.stopRecording}
          >
            {" "}
            {/* () => {this.stopRecording()}*/}
            Stop recording
          </button>
        )}
        <br />

        {this.state.blobURL ? (
          !this.state.submitted && this.props.submitRequired ? (
            <div>
              <button
                className="btn btn-info"
                disabled={!this.state.blobURL}
                onClick={this.uploadBlob}
              >
                Submit
              </button>
            </div>
          ) : (
            <div>
              <div className="bottomButtons">
                <a href={this.state.blobURL} download="audio.wav">
                  <button
                    className="btn btn-info"
                    disabled={!this.state.blobURL}
                  >
                    Download
                    {/* <img
                    alt="Download"
                    className="Icon"
                    src="baseline-save-24px.svg"
                  /> */}
                  </button>
                </a>
                {this.props.feedback && this.state.blobURL ? (
                  <div>
                    <button
                      className="btn btn-info"
                      // color='primary'
                      // className="feedback floatRight"
                      onClick={this.setOpenFeedback}
                    >
                      View Feedback
                    </button>
                    {this.state.isFeedbackOpen && (
                      <Dialog
                        onClose={this.setCloseFeedback}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isFeedbackOpen}
                      >
                        <DialogTitle
                          id="customized-dialog-title"
                          onClose={this.setCloseFeedback}
                        >
                          Feedback!
                        </DialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            {this.props.feedback}
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            autoFocus
                            onClick={this.setCloseFeedback}
                            color="primary"
                          >
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <a>
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      this.setState({
                        isRecording: false,
                        blobURL: null,
                        submitted: false,
                      });
                    }}
                    disabled={!this.state.blobURL}
                  >
                    Rerecord
                    {/* <img
                  alt="Download"
                  className="Icon"
                  src="baseline-save-24px.svg"
                /> */}
                  </button>
                </a>
                {this.props.resourceButtonLink && (
                  <a
                    className="btn btn-info"
                    href={this.props.resourceButtonLink}
                  >
                    View Resources
                  </a>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {this.props.submitRequired ? (
                  <div style={{ width: "80%", color: "#2E0056" }}>
                    You now have the opportunity to practice using this
                    vocabulary and get direct auditory feedback by listening to
                    your recording on the platform using the play and pause
                    buttons, downloading it, or rerecording the excerpt.
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

export default Record;
