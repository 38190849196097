import React, { Component } from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import { connect } from "react-redux";
import SurveyForm from "../../../components/Util/SurveyForm/SurveyForm.js";
import {demographicForm as demographicFormData} from '../../../static/forms/DemographicForm.js'
import {getFormData} from '../../../service/forms/dbService';

import '../../../CSS_share.css'

class DemographicPage extends Component {

    constructor(props){
        super(props);
        this.state = {formInfo: demographicFormData}
    }

    componentWillMount(){
        this.setState(getFormData(this.props.auth.uid, 'demographicForm') )
    }

    render(){

        return( 
        <DashFrame>
            {console.log("TEXT HERE: ", this.state.formInfo)}
            <SurveyForm
                form={this.state.formInfo} 
                userId={this.props.auth.uid} 
                finishOnBoarding={true}
                redirect={'/overview'}
            ></SurveyForm>
 
        </DashFrame>
        );
        
    }
}

const mapStateTOProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(DemographicPage);