import React, { Component } from "react";
import "./VocabRecognition.css";
import ItemLister from "./ItemLister";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { blue, amber } from "@material-ui/core/colors";
import { Radio } from "@material-ui/core";
import Button from "react-bootstrap/Button";
// Radio Styles
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const YellowRadio = withStyles({
  root: {
    color: amber[400],
    "&$checked": {
      color: amber[600],
    },
  },
  checked: {},
})((props) => <Radio {...props} />);
const BlueRadio = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600],
    },
  },
  checked: {},
})((props) => <Radio {...props} />);
class Highlighter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excerpt: [],
      highlightedWords: [],
      currentChoice: "",
      success: false,
      submitted:
        props.previousResponse !== undefined && props.previousResponse !== null,
    };
    this.choiceSelector = this.choiceSelector.bind(this);
    this.wordSelector = this.wordSelector.bind(this);
    this.removeTypeHandler = this.removeTypeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    try {
      // let excerp = this.props.excerpt.split(" ");
      let excerp =
        `Another aspect of the innovation system that must be attended to in driving diversity is more familiar: identifying, evaluating and selecting people to pursue innovation priorities. Many studies show that women (and other underrepresented groups) often receive lower evaluation scores when they submit research proposals, resumes, or business pitches—not because of a lack of quality, but because of assumptions being made about their capabilities (8), or because of the questions they are asked (9). To overcome these system-wide issues, it is necessary to consider the processes by which projects are selected: how solicitations are written, how venture pipelines are sourced, who is at the table during the evaluation process, what criteria are used, and how conversations are structured. Research shows that a wide range of voices needs to be heard in the selection process (10).  
      
      The criteria for selection are an especially critical part of how a system shapes diversity. In grant-making settings, decision-makers frequently rely on measures that are generally considered to be objective indicators of quality, e.g., citations, but are, in fact, biased in favor of over- not underrepresented groups (2). Thus, even signals of “merit” that are used to support innovation selections perpetuate a system that builds cumulative advantage for some groups over others. For example, if women receive smaller grants or smaller funding rounds early in their careers (11). This will have a cumulative long-term impact on productivity (in science or in commercialization), plausibly explaining the lower rates of invention observed by Koning et al. and across the economy. A “quick fix,” e.g., training aimed at unconscious bias, is not enough. Considering who is at the table making selection decisions and the criteria they use are essential to system change.  
      
      The interaction between what is prioritized and who is selected has important, unintended, cumulative, system-wide consequences that magnify inequality at every stage—from grant funding, to patenting, to translation and venture funding. Women have lower levels of confidence when they consider entering some domains (12), causing them to shy away from certain fields—a dynamic that is magnified if they do not observe role models (13) or decision-makers with whom they identify. Similarly, if women believe that they have a disadvantage when pursuing funding in traditionally male-focused fields—whether those are male-focused diseases in life sciences or traditional sectors of the economy—they may be less likely to apply in the first place from “lack of fit” (14). `.split(
          " "
        );
      let highlightedWords = [];

      const getType = (word) => {
        if (!this.state.submitted) return "none";
        let item;
        for (let j = 0; j < this.props.previousResponse.length; j++) {
          item = this.props.previousResponse[j];
          if (item.word === word) {
            return item.type;
          }
        }
        return "none";
      };

      for (let i = 0; i < excerp.length; i++) {
        let currWord = excerp[i].replace(/[^0-9a-z]/gi, "");
        highlightedWords.push({
          type: getType(currWord),
          word: currWord,
        });
      }
      this.setState({
        excerpt: excerp,
        highlightedWords: highlightedWords,
        currentChoice: "",
      });
    } catch (error) {
      console.log(error);
    }
  }
  searchAndSetWord(word, type) {
    let oldWords = this.state.highlightedWords;
    for (let i = 0; i < oldWords.length; i++) {
      if (oldWords[i].word === word) oldWords[i].type = type;
    }
    this.setState({
      highlightedWords: oldWords,
    });
  }
  distinctWordSet(type) {
    let distinctWords = [];
    for (let i = 0; i < this.state.highlightedWords.length; i++) {
      if (this.state.highlightedWords[i].type === type) {
        let found = false;
        for (let j = 0; j < distinctWords.length; j++) {
          if (distinctWords[j].word === this.state.highlightedWords[i].word) {
            found = true;
            break;
          }
        }
        if (!found) distinctWords.push(this.state.highlightedWords[i]);
      }
    }
    return distinctWords;
  }
  wordSelector(word) {
    if (this.state.currentChoice === "guessable") {
      this.searchAndSetWord(word, "guessable");
    }
    if (this.state.currentChoice === "unguessable") {
      this.searchAndSetWord(word, "unguessable");
    }
  }
  choiceSelector(event) {
    this.setState({
      currentChoice: event.target.value,
    });
  }
  removeTypeHandler(word) {
    this.searchAndSetWord(word, "none");
  }

  handleSubmit() {
    this.setState({ success: true, submitted: true, currentChoice: "" });
    const response = this.distinctWordSet("guessable").concat(
      this.distinctWordSet("unguessable")
    );
    this.props.updateFunction(response);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div>
            You will now see another excerpt from the article. You have a Blue
            highlighter and a Yellow highlighter. Click on the Yellow
            highlighter, and then click on the text to highlight words that you
            don’t know. Click on the Blue highlighter, and then click on the
            text to indicate words you are not sure of but can guess from the
            context. Note that the selected words will appear under each heading
            and can be removed using the X button next to them.
          </div>
          <div>
            Press <b>Submit</b> when you have finished using the highlighters.
          </div>
          <br></br>
        </div>
        {!this.state.submitted && (
          <RadioGroup
            style={{ width: "100%" }}
            name="currentChoice"
            onChange={this.choiceSelector}
          >
            <div className="radioClass" style={{ alignItems: "flex-start" }}>
              <div
                style={{
                  paddingLeft: "1.4rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  className="color-blue"
                  control={<BlueRadio />}
                  value="guessable"
                  label="Blue highlighter: Words I can guess from the context"
                />
                <h5>Guessable Words</h5>
                <ItemLister
                  words={this.distinctWordSet("guessable")}
                  handler={this.removeTypeHandler}
                />
              </div>
              <div
                style={{
                  paddingLeft: "1.4rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={<YellowRadio />}
                  className="color-yellow"
                  value="unguessable"
                  label="Yellow highlighter: Words I do not know"
                />

                <h5>Unknown words</h5>
                <ItemLister
                  words={this.distinctWordSet("unguessable")}
                  handler={this.removeTypeHandler}
                />
              </div>
            </div>
          </RadioGroup>
        )}

        <div className="border">
          {this.state.excerpt.map((word, index) => {
            return word != "\n" ? (
              <span>
                <span
                  key={index}
                  className={this.state.highlightedWords[index].type}
                  onClick={() => {
                    this.wordSelector(this.state.highlightedWords[index].word);
                  }}
                >
                  {word}
                </span>
                <span> </span>
              </span>
            ) : (
              <br></br>
            );
          })}
        </div>

        {!this.state.submitted && (
          <Button
            style={{
              background: "#7270a1",
              borderColor: "rgba(0, 32, 78)",
              marginTop: "30px",
            }}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        )}

        {this.state.success ? (
          <Alert
            icon={<CheckCircleOutlineIcon fontSize="inherit" color="primary" />}
            className="popup-alert"
            severity="info"
          >
            <AlertTitle>Success! Your answer has been submitted!</AlertTitle>
          </Alert>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Highlighter;
