import React from "react";
import Grid from "@material-ui/core/Grid";

const Legend = () => {
  const getRect = (color) => (
    <svg width="20" height="20">
      <rect width="20" height="20" style={{ fill: color }} />
    </svg>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        {getRect("orange")} {"  "} Your Score Range
      </Grid>
      <Grid item xs={1.5}>
        {getRect("navy")} {"  "} Your Score
      </Grid>
      <Grid item xs={3}>
        {getRect("hsl(0, 100%, 60%)")} {"  "} Your Self-Assessment Score
      </Grid>
    </Grid>
  );
};

export default Legend;
