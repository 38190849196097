import React, { Component } from "react";
import DashFrame from "../../../components/DashFrame/DashFrame";
import DashboardHeader from "../DashboardHeader";
import "../../../CSS_share.css";
import { Typography } from "@material-ui/core";

class DashboardPage extends Component {
  state = {
    scoreData: null,
  };

  render() {
    return (
      <DashFrame>
        {/* <Button variant="outlined" onClick={ e => {fixExistingModule('8fFnJAnwV4SSrV6kUKyA',moduleData)}}> EMERGENCY REVERT </Button> */}
        <DashboardHeader overview={true}></DashboardHeader>
        <Typography variant="h6" style={{ marginLeft: "3rem", width: "60%" }}>
          Welcome to the <b>APLUS</b> platform!
          <br />
          <br />
          To access the modules, use the bar on your left where you will find
          the <b>Academic Integrity</b>, <b>Oral Communication</b> and{" "}
          <b>Written Communication</b> modules. Here you can also access
          additional resources and our FAQs.
          <br />
          <br />
          You can enter and exit the platform at any time; your work will be
          saved. Click on your name in the top right corner to logout.
          <br />
          <br />
          Please click on the <b>Academic Integrity</b> Module button to begin.
          <br />
        </Typography>
        {/* <Row style={{"padding-left":"10px", "margin": "25px"}}>
                <Button style={{"margin": "10px"}} size="medium" variant="outlined" color="primary" onClick={this.handleConsentFormClick}>
                    Change Consent
                </Button >

                <Button style={{"margin": "10px"}} size="medium" variant="outlined" color="primary" onClick={this.handleDemoFormClick}>
                    Change Demographic
                </Button >
            </Row> */}
      </DashFrame>
    );
  }
}

export default DashboardPage;
