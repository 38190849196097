import { Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import React from "react";

const NoScoreAvailable = () => {
  return (
    <Container>
      <Typography variant="h5" color="navy">
        Your Scores are Currently Unavailable
      </Typography>
      <Typography variant="body">
        Unfortunately, we couldn't find any scores related to your account.
        Please make sure that you have finished all modules. If you've already
        everything and are still unable to see your results, your submissions
        might still be getting graded.
      </Typography>
    </Container>
  );
};

export default NoScoreAvailable;
