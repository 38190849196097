import React, { Component } from "react";

import "./ItemLister.css";

class ItemLister extends Component {
  render() {
    return (
      <div>
        <ul className="uiStyles">
          {this.props.words.map((wordObj) => {
            return (
              <li className="liStyles" onClick={() => this.props.handler(wordObj.word)}>
                {wordObj.word}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ItemLister;
