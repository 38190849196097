import React from "react";
import PdfRender from "./PdfRender";
import { Button } from "@material-ui/core";

class DraftAndPdf extends React.Component {
  state = {
    draftResponse: "",
    currentPdf: 0,
  };

  render() {
    return (
      <div style={{display: 'flex'}}>
        <div style={{width: '50%'}}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              margin: '30px 0'
            }}
          >
            {this.props.pdf.length > 1
              ? this.props.pdf.map((_, index) => {
                  return (
                    <div>
                      <Button
                        style={{
                          background: "#7270a1",
                          borderColor: "rgba(0, 32, 78)",
                          color: "white",
                        }}
                        onClick={() => {
                          this.setState({ currentPdf: index });
                        }}
                      >
                        {index == 0 ? "Main" : `Source ${index}`}
                      </Button>
                    </div>
                  );
                })
              : ""}
          </div>
          <PdfRender
            style={{ height: "100rem" }}
            draft={true}
            pdf={this.props.pdf[this.state.currentPdf]}
          />
        </div>
        <div className="box">
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              // width: "90%",
            }}
          >
            <textarea
              value={this.props.textValue}
              onChange={this.props.onChange}
              style={{
                fontSize: "20px",
                height: "90%",
                width: "90%",
              }}
            ></textarea>
            <div style={{width: "90%"}}>Word Count: {this.props.wordCount}</div> 
          </form>
        </div>
      </div>
    );
  }
}

export default DraftAndPdf;
