import React from "react";
import {
  Card,
  Box,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import NormalDistributionChart from "./NormalDistributionChart";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Container } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";

const snakeCaseToTitleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

const camelCaseToTitleCase = (s) => {
  try {
    const result = s.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  } catch (e) {
    return s;
  }
};

const HELP_BY_HEADER = {
  "Oral Reading Fluency":
    "Your oral reading fluency score represents your ability to read out loud. Your overall score shows how strong you are in this skill; scores have a mean of 60 with a maximum of 90 and minimum of 30. The additional details show the components of oral reading fluency: speed and accuracy. Accuracy refers to the percentage of words you read correctly, and speed refers to your pace of your reading. WCPM is a composite score combining accuracy and speed, it stands for words correct per minute.",
  Writing:
    "Your writing score represents your ability to write at an academic level in English. Your overall score shows how strong you are in this skill; scores have a mean of 60 with a maximum of 90 and minimum of 30. The additional details show your scores on each skill. Skills are based on our machine learning algorithm and range from 1-5.",
  Speaking:
    "Your speaking score represents your ability to speak with fluent English. This score is based on our machine learning algorithm trained to evaluate expression and volume, phrasing, pace, and smoothness. Your overall score shows how strong you are in this skill; scores have a mean of 60 with a maximum of 90 and minimum of 30.",
  Vocabulary:
    "Your vocabulary score represents your ability to recognize words in a challenging academic text. Your overall score shows how strong you are in this skill; scores have a mean of 60 with a maximum of 90 and minimum of 30. The additional details shows your estimated vocabulary size based on our algorithm. The average adult native English speaker has a vocabulary size between 20,000 and 35,000 words. However, much less is needed to be considered fluent. Fluent non-native speakers generally know more than 5,000 words.",
  Listening:
    "Your listening score represents your ability to answer questions based on a lecture that you listened to. Your overall score shows how strong you are in this skill; scores have a mean of 60 with a maximum of 90 and minimum of 30. The additional details show the number of questions you answered correctly, your percentage correct, and the questions you got wrong for your review.",
  Reading:
    "Your reading score represents your ability to answer questions based on an academic article that you read. Your overall score shows how strong you are in this skill; scores have a mean of 60 with a maximum of 90 and minimum of 30. The additional details show the number of questions you answered correctly, your percentage correct, and the questions you got wrong for your review.",
};

const DEFAULT_TIP_CONTENT = "No tips available at the moment.";

const SimpleDialog = (props) => {
  const { onClose, content, open, header } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{header}</DialogTitle>
      <Container style={{ marginBottom: "15px" }}>
        <Typography variant="body1">{content}</Typography>
      </Container>
    </Dialog>
  );
};

const DetailedCard = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState(DEFAULT_TIP_CONTENT);
  const [dialogHeader, setDialogHeader] = React.useState("Tips");

  const handleClickOpenTips = (tip) => {
    setDialogHeader("Tips");
    setDialogContent(tip || DEFAULT_TIP_CONTENT);
    setOpen(true);
  };

  const handleClickOpenHelp = () => {
    setDialogHeader(data.header);
    setDialogContent(HELP_BY_HEADER[data.header] || DEFAULT_TIP_CONTENT);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Card variant="outlined" sx={{ height: "100%" }}>
        <CardHeader
          title={data.header}
          style={{ background: "#43425D", color: "white" }}
          action={
            <IconButton onClick={handleClickOpenHelp}>
              <Help style={{ color: "lightgrey" }} />
            </IconButton>
          }
        />
        <CardContent>
          {data.details && (
            <>
              {Object.entries(data.details).map(([key, val]) => (
                <>
                  {
                    <Typography
                      variant="h5"
                      style={{ textAlign: "center", marginBottom: "10px" }}
                    >
                      {snakeCaseToTitleCase(key)}
                    </Typography>
                  }
                  {val?.scaledScore && (
                    <>
                      <Typography variant="h6">
                        Overall Score: {val.scaledScore}
                      </Typography>
                      <Box display="flex" style={{ alignItems: "center" }}>
                        <Box style={{ width: "80%", height: "150px" }}>
                          <NormalDistributionChart score={val.scaledScore} />
                        </Box>
                        <Button
                          variant="contained"
                          style={{
                            width: "20%",
                            backgroundColor: "#43425D",
                            color: "white",
                          }}
                          onClick={() => handleClickOpenTips(val?.tips)}
                        >
                          TIPS
                        </Button>
                      </Box>
                    </>
                  )}

                  <Typography variant="h6">Additional Details:</Typography>
                  <ul>
                    {Object.entries(val).map(([key2, val2]) => {
                      switch (key2) {
                        case "scaledScore":
                          return null;
                        case "tips":
                          return null;
                        case "wcpm":
                          return (
                            <li>
                              <strong>WCPM</strong>:{` ${val2}`}
                            </li>
                          );
                        case "transcript":
                          return (
                            <li>
                              <strong>{camelCaseToTitleCase(key2)}</strong>:
                              {` ${val2.transcript}`}
                            </li>
                          );
                        default:
                          return (
                            <li>
                              <strong>{camelCaseToTitleCase(key2)}</strong>:
                              {` ${val2}`}
                            </li>
                          );
                      }
                    })}
                  </ul>
                </>
              ))}
            </>
          )}
        </CardContent>
      </Card>
      {
        <SimpleDialog
          open={open}
          onClose={handleClose}
          content={dialogContent}
          header={dialogHeader}
        />
      }
    </Box>
  );
};

export default DetailedCard;
