import React, { Component } from 'react';
import CorrectIcon from '@material-ui/icons/DoneOutlineRounded';
import IncorrectIcon from '@material-ui/icons/CloseRounded';

const MCQ = 'mcq';
const MSQ = 'msq';
//const defaultC = 'default';


class QuizExp extends Component {

    renderIcon(index, type) {
        if (type === MCQ) {
            if ((this.props.answersTF)[index] === true)
                return (
                    <div className="quizIcon-container ">
                        <CorrectIcon className='correct-icon' />
                    </div>
                )
            else if ((this.props.answersTF)[index] === false)
                return (
                    <div className="quizIcon-container">
                        <IncorrectIcon className='incorrect-icon' />
                    </div>
                )
        }
        else if (type === MSQ) {
            if (this.props.answersTF[index] != null) {
                if (this.props.answersTF[index] === true)

                    return (<div className="quizIcon-container ">
                        <CorrectIcon className='correct-icon' />
                    </div>)
                else

                    return (
                        <div className="quizIcon-container">
                            <IncorrectIcon className='incorrect-icon' />
                        </div>)
            }

        }

    }

    renderEXPs(index) {
        // console.log('renderEXPs')
        //console.log('DB,renderEXPs, options = ',this.props.options)
        return (
            <div style={{marginBottom: '1rem'}} className='div'>
                <div className="exp-option-contaner">
                    {this.renderIcon(index, this.props.type)}

                    <div className="quiz-exp-option-container">
                        <span>{(this.props.answers)[index].content}</span>
                    </div>

                </div>
                {/* <div className="exp-text-contaner">
                    <p><b>Explanation: </b>{(this.props.explanations[0])[index]}</p>
                </div> */}
                <span><strong>Explanation: </strong>{(this.props.explanations[0])[index]}</span>

            </div>

        )

    }

    getRenderIdx() {
        if (this.props.answersTF.length === 2) return [...this.props.checkedIndex]
        const renderInx = [...this.props.checkedIndex]
        this.props.answersTF.forEach((element, idx) => {
            if (element && !this.props.checkedIndex.includes(idx)) renderInx.push(idx)
        });
        console.log("Redner Index Array", renderInx)
        return renderInx;
    }

    render() {
    console.log(this.props);

        if (this.props.type === MSQ || this.props.type === MCQ) {
            //get index array
            var indexArray = [];
            for (var i = 0; i < this.props.answers.length; i++) {
                indexArray.push(i)
            }

            return (
                <div
                    className={'quiz-exp ' + this.props.show} id='test'>
                    {this.props.submit > 1 ? indexArray.map(each => this.renderEXPs(each)) : this.getRenderIdx().map((index) => this.renderEXPs(index)) }
                    {/* {indexArray.map(each => this.renderEXPs(each))} */}
                </div>
            );
        }
        else {
            return (
                <div>
                    <h1>EXP Error</h1>
                </div>)

        }

    }
}


export default QuizExp;

