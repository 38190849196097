import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "react-bootstrap/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { SizeMe } from "react-sizeme";

import "./pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfRender(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  return (
    <SizeMe
      monitorHeight
      refreshRate={128}
      refreshMode={"debounce"}
      render={({ size }) => (
        <div className="outer">
          <div className="parent">
            {/* <IconButton
              type="submit"
              onClick={() =>
                1 <= pageNumber - 1 ? setPageNumber(pageNumber - 1) : null
              }
            >
              {" "}
              <ArrowBackIosOutlinedIcon></ArrowBackIosOutlinedIcon>
            </IconButton> */}
            <div className="all-page-container"> 
            <Document
              className="pdf"
              file={props.pdf}
              renderMode="svg"
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >      
            {Array.from(new Array(numPages), (el, index) => (
              <Page scale={props.draft ? 1 : props.stretch ? 2 : size.width < 100 ? 2 : size.width > 400 ? 3/4 : 2} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
              {/* <p>
                Page {pageNumber} of {numPages}
              </p> */}
              {/* <Page
                width={900}
                //width={size.width > 1200 ? size.width - 200 : size.width - 500}
                //scale={size.width < 1200 ? 2 : 1}
                onLoadSuccess={removeTextLayerOffset}
                renderTextLayer={true}
                pageNumber={pageNumber}
              /> */}
            </Document>
            </div>
            {/* <IconButton
              type="submit"
              onClick={() =>
                numPages >= pageNumber + 1
                  ? setPageNumber(pageNumber + 1)
                  : null
              }
            >
              {" "}
              <ArrowForwardIosOutlinedIcon></ArrowForwardIosOutlinedIcon>
            </IconButton> */}
          </div>
          {/* <div className="pagintationOuter">
            {numPages
              ? Array.from({ length: numPages }, (_, i) => i + 1).map(
                  (_, l) => {
                    l = l + 1;
                    return (
                      <Button
                        style={l===pageNumber ? {
                          background: "#7270a1",
                          "borderColor": "rgba(0, 32, 78)",
                          "marginRight": "10px",
                        } : {background: "#43425D",
                        "borderColor": "#43425D",
                        "marginRight": "10px",}}
                        onClick={() => setPageNumber(l)}
                      >
                        {l}
                      </Button>
                    )
                  }
                )
              : null}
          </div> */}
        </div>
      )}
    />
  );
}

export default PdfRender;