import React, { Component } from 'react';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { ExitToApp } from '@material-ui/icons';
import { connect } from 'react-redux';
import { logOutAction } from '../../../store/actions/authActions';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';

import './Navbar.css';

class TopNavBar extends Component {

    handleClick = () => {
        this.props.logOutAction();
    }

    render() {
        return (
            <Navbar className="dash-navbar" bg="white" >
                <button className="sidebar-button" onClick={this.props.onButtonClick}>
                    <MenuIcon className="sidebar-button-icon" onClick={this.handleSibeBarButton}></MenuIcon>
                </button>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto" >
                        <Avatar
                            alt={this.props.profile.firstName + " " + this.props.profile.lastName}
                            src={this.props.imageUrl} />
                        <NavDropdown className="dropdown-menu-right" id="dropdown-menu-content"
                            title={this.props.profile.firstName + " " + this.props.profile.lastName}>
                            <NavDropdown.Item onClick={this.handleClick}><ExitToApp height={10} />Log Out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logOutAction: () => dispatch(logOutAction())
    }
}
const mapStateTOProps = (state) => {

    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    };
}

export default connect(mapStateTOProps, mapDispatchToProps)(TopNavBar);
