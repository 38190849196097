import React from "react";
import {Dialog, Button} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


class InstructionsDialog extends React.Component {
  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.isOpen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.onClose}
        >
          Instructions
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div>Instructions: Write a critical reflection</div>
            <div>- Length: 500 words</div>
            <div>- Include: </div>
            <ul>
              <li>
                Content:
                <ul>
                  <li>Introduction</li>
                  <li>Summary</li>
                  <li>Critique</li>
                  <li>Short conclusion</li>
                </ul>
              </li>

              <li>
                Additional Source:
                <ul>
                  <li>Information from at least one other article</li>
                </ul>
              </li>
              <li>
                Citation:
                <ul>
                  <li>In-text citation</li>
                  <li>Reference list (italics not required)</li>
                </ul>
              </li>
            </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.props.onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default InstructionsDialog;
