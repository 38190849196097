import React, { Component } from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import { connect } from "react-redux";
import SurveyForm from "../../../components/Util/SurveyForm/SurveyForm.js";
import {consentForm as ConsentFormData }  from '../../../static/forms/ConsentForm.js';
import {getFormData} from '../../../service/forms/dbService';
import '../../../CSS_share.css'

class ConsentPage extends Component {

    constructor(props){
        super(props);
        this.state = {formInfo: ConsentFormData}
    }

    componentWillMount(){
        this.setState(getFormData(this.props.auth.uid, 'consentForm') )
    }


    render(){
        return( 
        <DashFrame>
            
            <SurveyForm
                form={this.state.formInfo} 
                userId={this.props.auth.uid} 
                finishOnBoarding={true}
                redirect={'/overview'}
            ></SurveyForm>
        </DashFrame>
        );
        
    }
}

const mapStateTOProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(ConsentPage);