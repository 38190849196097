
export var AIPostSurveyForm = {
    "formHeader": "Academic Integrity – Experience Survey & Certificate of Completion",
    "formType": 'academicIntegrityPostSurvey',
    "formText": {
        "0": "Now that you have completed the Academic Integrity module, please complete the brief user experience survey. Your Certificate of Completion will download after completing the survey.",
        "1": "If you consented to participate in the research study, your responses will be anonymized and used only for research purposes. If you didn’t consent to participate in the research study, your responses will help improve the platform for future students. If you have any questions, please contact us at: aplusuoft.contact@gmail.com"
    },
    "formSections": {
        "0": {
            "sectionHeader": "",
            "sectionText": {
            },
            "sectionQuestion": {
                "0": {
                    "qText": "1. Since completing the module, how knowledgeable are you about the concept of academic integrity? ",
                    "qType": "mcq",
                    "options": ["Not very knowledgeable", "Knowledgeable", "Extremely knowledgeable"],
                    "response": null
                },
                "1": {
                    "qText": "2. In  academia we  use distinct communication rules and styles. Since completing the module, please indicate your level of awareness of the following academic styles:",
                    "qType": "table",
                    "tableTitles": ["Very low", "Below Average", "Average", "Above Average", "Very High"],
                    "options": ["APA Style", "MLA Style", "Chicago Style"],
                    "response": [null, null, null]
                },
                "2": {
                    "qText": "3. It is important to acknowledge and give credit to the source of information. Since completing the module, please indicate your level of awareness of the following:",
                    "qType": "table",
                    "tableTitles": ["Very low", "Below Average", "Average", "Above Average", "Very High"],
                    "options": ["Citation", "Credibility of Sources", "Plagiarism", "Collaborative Group Work"],
                    "response": [null, null, null, null]
                },
                "3": {
                    "qText": "4. How helpful was each of the following topics? ",
                    "qType": "table",
                    "tableTitles": ["Not at all helpful", "Not very helpful", "Helpful", "Very helpful", "Extremely helpful"],
                    "options": ["Topic 1: Academic Style", "Topic 2: Evaluating Sources", "Topic 3: Accurate Citations", "Topic 4: Citation Styles", "Topic 5: Group Participation"],
                    "response": [null, null, null, null, null]
                },
                "4": {
                    "qText": "5. How helpful was each of the following?",
                    "qType": "table",
                    "tableTitles": ["Not at all helpful", "Not very helpful", "Helpful", "Very helpful", "Extremely helpful"],
                    "options": ["Watching  informational videos", "Reflecting on topic questions", "Learning content by completing quizzes or other activities", "Comparing my answers to the feedback", "Other (please specify):"],
                    "response": [null, null, null, null, null]
                },
                "5": {
                    "qText": "If you answered Other for above, please specify what helped:",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "optional": true
                },
                "6": {
                    "qText": "6. After taking the module, how likely will you be to access university resources (e.g. up-to-date citation style guides, online information, workshops, courses, writing centres, etc.) to learn more about academic integrity?",
                    "qType": "mcq",
                    "options": ["Not at all likely", "Not very likely", "Likely", "Very Likely", "Extremely Likely"],
                    "response": null
                },
                "7": {
                    "qText": "7. How likely are you to recommend the Academic Integrity Module to your peers?",
                    "qType": "mcq",
                    "options": ["Not at all likely", "Not very likely", "Likely", "Very Likely", "Extremely Likely"],
                    "response": null
                },
                "8": {
                    "qText": "8. Is there anything about Academic Integrity that you had hoped to learn but was not covered in this module?  ",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "optional": true
                },
                "9": {
                    "qText": "9. Are there any other suggestions you would like to add?",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "optional": true
                }
            }
        },
        "1": {
                "sectionHeader": "",
                "sectionText": {
                    "0": "Thank you so much for completing the follow-up Survey on Academic Integrity. Your input is valuable for research and platform improvement.",
                },
                "sectionQuestion": {
                }
            },
    },
    "formAgreement": {
        "active": false,
        "textLine": "I agree/Agree/I consent",
        "response": false
    }
}

