import { db } from '../../FirestoreRedux.js';
/**
 * Return the id and data of the active session.
 * @return {code:200/400, sessionInfo: [sessionId, sessionData]} 
 */
export const getFormData = (userId, formId) => {
    
    let formDocRef = db.collection("users").doc(userId)
    .collection('forms').doc(formId)
    return formDocRef.get().then(function(doc) {
        if (doc.exists) {
            
            return {code:200, formInfo: doc.data()}
        } else {
            return {code:200, formInfo: {}}
        }
    })
    .catch(function(error) {
        return {code:400, formInfo: error}
    });
    
}

/**
 * Return the id and data of the active session.
 * @param {String} userId 
 * @param {String} formId 
 * @param {Object} updatedFormData 
 * @return {code:200/400, sessionInfo: [sessionId, sessionData]} 
 */
export const submitFormData = (userId, formId, updatedFormData) => {

    if (formId === 'academicIntegrityPostSurveyForm' || formId === "oralPostSurveyForm" || formId === "writtenPostSurveyForm") {
        return db.collection('users').doc(userId).collection('forms').doc(formId).set(updatedFormData)
    }
    return db.collection("users").doc(userId)
      .collection('forms').doc(formId)
      .update(updatedFormData)
    //   .then(() => {
    //       // Update on boarding section when consent form is filled
    //       let onboardingMessage = "Form Submitted!"
          
    //       return {code: 200, message: onboardingMessage}
    //   })
    //   .catch((error) => {
    //       return {code: 400, message: error}
    //   })
}

export const completeOnboarding = (userId) => {
    if (userId === undefined){
        return db.collection("users").doc("").update({'onboarding': false})
    }
    return db.collection("users").doc(userId).update({'onboarding': false})
}