const t1 = {
    subModuleTitle: "Introduction to Oral Communication",
    userState: { locked: false, completed: false },
    content: {
        0: {
            sectionTitle: "1.1. Introduction to Topic 1",
            sectionIconTitle: "Intro",
            sectionIcon: "info",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "video",
                srcType: "url",
                url: "videos/oral/t1.1.mp4",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "text",
                text: "This oral module will support you in the development of a seminar presentation. You will be guided through the following tasks: <ul> <li>Listen to a lecture</li> <li>Answer questions about the lecture</li> <li>Write some discussion questions about the lecture</li> <li>Write a summary of the lecture</li> <li>Practice reading your summary out loud to build your oral reading fluency</li> <li>Prepare a presentation based on the lecture</li> <li>Do a self-assessment about your presentation draft</li> <li>Finalize and upload your presentation to the platform</li> </ul>",
                userState: {
                    viewed: false
                }
            },
        },
        1: {
            sectionTitle: "1.2. Listen to a Lecture",
            sectionIconTitle: "Lecture",
            sectionIcon: "info",
            userState: { locked: true, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "You are going to watch a brief lecture from an OISE professor. All of the tasks in the module will be based on this lecture. As you listen, please pay close attention. After you watch the lecture, you will be asked to answer comprehension questions about it.  Are you ready to watch? You will only be able to watch it <b>ONCE</b> at this time, so listen carefully and <b>take notes</b>. Press Play on the video to begin.",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "video",
                srcType: "url",
                url: "videos/oral/t1.mp4",
                userState: {
                    viewed: false
                }
            },
            2: {
                subSectionTitle: "",
                type: "text",
                text: "Please move to the next section to complete the comprehension questions.",
                userState: {
                    viewed: false
                }
            },
        },
        2: {
            sectionTitle: "1.3. Lecture Comprehension Questions",
            sectionIconTitle: "Comprehend",
            sectionIcon: "quiz",
            sectionAlert: "Are you sure you are ready to move to the next section? Please make sure that you have watched the full video before proceeding. If you have finished, press \"OK\" then go to the \"COMPREHEND\" tab.",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "We hope you enjoyed listening to the lecture. Please think about what you listened to and answer the following multiple-choice questions. Use your notes and the text to help you. You will be provided with feedback after you’ve answered each question. Click on the <b>SUBMIT</b> button when you are finished.",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "1. What does Dr. Rajendram state as the purpose of the lecture?",
                    options: [
                        "A. To share examples of second language teaching activities",
                        "B. To critique two widely used second language teaching methods",
                        "C. To talk about her own second language teaching experiences",
                        "D. To introduce two widely used second language teaching methods"
                    ],
                    answerIndex: 3,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about what Dr. Rajendram said at the beginning of the lecture. What did she say was the main goal of the lecture? ",
                        "This is not the correct answer. D is the correct answer. At the beginning of the lecture Dr. Rajendram explictly stated, \"Today I'll be talking to you about two second language teaching methods which are widely used around the world\".",
                        null
                    ],
                    correctText: "Great job! D is the correct answer. At the beginning of the lecture Dr Rajendram explicitly stated, \"Today I'll be talking to you about two second language teaching methods which are widely used around the world\".",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            2: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "2. What does Dr. Rajendram mean when she says the teacher is more of a facilitator in the Communicative Language Teaching lessons? ",
                    options: [
                        "A. the teacher does most of the talking and students play language games ",
                        "B. the teacher translates everything and the students do individual work",
                        "C. the teacher lets students lead activities and students do pair or group work",
                        "D. the teacher lectures to the students and students do pair or group work"
                    ],
                    answerIndex: 2,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about Communicative Language Teaching, its purpose, and what type of role the teacher has. Are they active or passive? What types of things does the teacher do? When you are ready, choose the correct answer.",
                        "This is not the correct answer. C is the answer because a facilitator is someone who supports a person or group of people in doing something more easily but does not lead the activity.",
                        null
                    ],
                    correctText: "Great job! C is the answer because a facilitator is someone who supports a person or group of people in doing something more easily but does not lead the activity.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            3: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "3. What is the main idea of the lecture?",
                    options: [
                        "A. The Communicative Language Teaching Method is considered better than the Grammar Translation Method because it focuses on real life speaking tasks.",
                        "B. The Communicative Language Teaching Method and the Grammar Translation Method are two widely used teaching methods with different activities and purposes.",
                        "C. The Grammar Translation Method is considered better than the Communicative Language Teaching Method because it focuses on proper grammar and reading.",
                        "D. The Communicative Language Teaching Method and the Grammar Translation Method are two widely used teaching methods with similar activities and purposes. "
                    ],
                    answerIndex: 1,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the two teaching methods Dr. Rajendram discussed. How are they similar and different? When you are ready, choose the correct answer.",
                        "This is not the correct answer. B is the correct answer because it includes the 2 methods Dr. Rajendram discussed and provides important information from the lecture.",
                        null
                    ],
                    correctText: "Great job! B is the correct answer because it includes the 2 methods Dr. Rajendram discussed and provides important information from the lecture.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            4: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "4. Group work, limited use of first language, and students leading the class are all features of the  _______ method.",
                    options: [
                        "A. Communicative Language Teaching ",
                        "B. Grammar translation ",
                        "C. Deductive ",
                        "D. Authentic  "
                    ],
                    answerIndex: 0,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the two methods Dr. Rajendram  discussed. What were some key activities she mentioned?",
                        "This is not the correct answer.  A is the correct answer. Communicative Language Teaching involves limited use of first language, group work, and students leading the class.",
                        null
                    ],
                    correctText: "Great job! A is the correct answer. Communicative Language Teaching involves the limited use of first language, group work, and students leading the class.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            5: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "5. Dr. Rajendram uses the story of Little Red Riding Hood twice during the lecture. Why does she use this story? ",
                    options: [
                        "A. to illustrate how best to use the Grammar Translation Method ",
                        "B. to introduce the audience to a popular fairy tale from the 15th century",
                        "C. to provide examples from her own classroom teaching experience",
                        "D. to show how the same story can be used differently based on teaching method"
                    ],
                    answerIndex: 3,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the purpose of the lecture and when the professor used the story. When you are ready, choose the correct answer.",
                        "This is not the correct answer. D is the correct answer because Dr Rajendram used Little Red Riding Hood in both of her classroom examples of the two methods that she discussed.",
                        null
                    ],
                    correctText: "Great job! D is the correct answer because Dr Rajendram used Little Red Riding Hood in both of her classroom examples of the 2 methods she discussed.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            6: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "6. Dr. Rajendram notes that the Communicative Language Teaching method uses authentic materials. Which of these is NOT authentic?",
                    options: [
                        "A. an episode of Friends ",
                        "B. a series of graded readers",
                        "C. an article from a newspaper (e.g. the Globe and Mail)",
                        "D. an instructional manual"
                    ],
                    answerIndex: 1,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer. ",
                        "This is not the correct answer. The opposite of authentic is fake. Think about the meaning of authentic and, when you are ready, choose the correct answer.",
                        "This is not the correct answer. B is the correct answer because authentic means real and an episode of Friends, an instructional manual, and an article in the Globe and Mail newspaper are materials found in real life. They were not designed for the class.",
                        null
                    ],
                    correctText: "Great job! B is the correct answer because authentic means real and an episode of Friends, an instructional manual, and an article in the Globe and Mail newspaper are materials found in real life. They were not designed for the class.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            7: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "7. Grammar is taught in a specific way in Communicative Language Teaching. What might be the reason grammar is taught this way? ",
                    options: [
                        "A. because accurate grammar is necessary for communicative competence",
                        "B. because students are not interested in learning authentic grammar  ",
                        "C. because grammar is easier to learn if teachers don't emphasize it",
                        "D. because errors are sometimes present in authentic language "
                    ],
                    answerIndex: 3,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the Purpose of Communicative Language Teaching. Is the purpose to be very accurate, or is it something else? When you are ready, choose the correct answer.",
                        "This is not the correct answer. D is the answer because Communicative Language Teaching focuses on real-life (sometimes potentially grammatically incorrect) language use.",
                        null
                    ],
                    correctText: "Great job! D is the correct answer because Communicative Language Teaching focuses on real-life (sometimes potentially grammatically incorrect) language use.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            8: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "8. Which Grammar-Translation activities did Dr. Rajendram mention during her lecture? ",
                    options: [
                        "A. memorizing lists of words, fill-in-the-blanks, remembering grammatical rules",
                        "B. memorizing lists of words, playing language games, remembering grammatical rules",
                        "C. fill-in-the-blank exercises, doing listen and repeat drills, role playing a dialogue",
                        "D. playing language games, role playing a dialogue, and using authentic materials"
                    ],
                    answerIndex: 0,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about all of the features and activities of the Grammar Translation method. It is an older method and quite traditional.",
                        "This is not the correct answer. A is the correct answer. Dr. Rajendram listed: memorizing lists of words, fill-in-the-blanks, and remembering grammatical rules as activities associated with the Grammar Translation Method.",
                        null
                    ],
                    correctText: "Great job! A is the correct answer. Dr. Rajendram listed: memorizing lists of words, fill-in-the-blanks, and remembering grammatical rules as activities associated with the Grammar Translation Method.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            9: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "9. What would be a good alternative title for this lecture?",
                    options: [
                        "A. How to teach a Second Language with Little Red Riding Hood",
                        "B. A Guide to Second Language Translation Approaches",
                        "C. An Introduction to Second Language Teaching Approaches",
                        "D. Using Authentic Materials in Second Language classrooms"
                    ],
                    answerIndex: 2,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the main idea of the lecture and try to find a title that includes the same ideas. When you are ready, choose the correct answer.",
                        "This is not the correct answer. C is the answer because Dr. Rajendram said that the purpose of her lecture was to introduce two second language teaching methods. The name of her talk is, \"Second Language Teaching Methods\". Approach is a synonym for method.",
                        null
                    ],
                    correctText: "Great job! C is the answer because Dr. Rajendram said that the purpose of her lecture was to introduce two second language teaching methods. The name of her talk is, \"Second Language Teaching Methods\". Approach is a synonym for method.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            10: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "10. What does Dr. Rajendram mean when she describes one of the methods as deductive? ",
                    options: [
                        "A. students learn grammar by memorizing grammatical rules",
                        "B. students learn grammar by watching authentic discussions",
                        "C. students learn grammar by using it with their peers",
                        "D. students learn grammar by listening to examples of perfect pronunciation "
                    ],
                    answerIndex: 0,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer.  In this lecture, the opposite of deductive is intuitive/inductive, meaning it happens naturally without structured practice. Look at the other answers to see which one is a good example of a deductive way of learning grammar.  When you are ready, choose the correct answer.",
                        "This is not the correct answer. A is the correct answer because in this lecture, deductive means learning something in a structured, rule-bound way. Dr.Rajendram mentions that the Grammar Translation Method teaches grammar deductively, and she gives the example of learning through memorization.",
                        null
                    ],
                    correctText: "Great job! A is the correct answer because, in this lecture, deductive means learning something in a structured, rule-bound way. Dr.Rajendram mentions that the Grammar Translation Method teaches grammar deductively, and she gives the example of learning through memorization.  ",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            11: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "11. What task would be the most appropriate activity in a classroom using the Communicative Language Teaching method? ",
                    options: [
                        "A. writing a research article in the target language",
                        "B. participating in a peer discussion in the target language",
                        "C. diagraming sentences in the target language",
                        "D. reading a manuscript in the target language"
                    ],
                    answerIndex: 1,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the Communicative Language Teaching method. What is the purpose of it? Why would a teacher use it? When you are ready, choose the correct answer.",
                        "This is not the correct answer. B is the answer because Communicative Language Teaching focuses on real-life language use and communication.",
                        null
                    ],
                    correctText: "Great job! B is the correct answer because Communicative Language Teaching focuses on real-life language use and communication.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            12: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "12. According to the lecture, what is the fundamental purpose of language learning in the Grammar-Translation Method? ",
                    options: [
                        "A. to write texts in the target language",
                        "B. to speak to someone in the target language",
                        "C. to read something written in the target language",
                        "D. to participate in a classroom discussion"
                    ],
                    answerIndex: 2,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about all of the features and activities of the Grammar Translation method. It is an older method and quite traditional.",
                        "This is not the correct answer. The correct answer is C. In the lecture, Dr. Rajendram said, \"In the GTM, the fundamental purpose of language learning is to be able to read texts, especially literature texts, in the target language.\"",
                        null
                    ],
                    correctText: "Great job! C is the correct answer. In the lecture, Dr. Rajendram said, \"In the GTM, the fundamental purpose of language learning is to be able to read texts, especially literature texts, in the target language.\"",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            13: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "13. How is this lecture structured? ",
                    options: [
                        "A. As a narrative: a story with a beginning, middle, and end",
                        "B. As a talk: one or more ideas are explained with comparisons",
                        "C. As an oral argument: with a main thesis and evidence providing support",
                        "D. As a demonstration: describing each step of a process in sequence "
                    ],
                    answerIndex: 1,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the topic of the lecture and the way Dr. Rajendram discussed it. When you are ready, choose the correct answer.",
                        "This is not the correct answer. B is the answer because Dr. Rajendram explained two methods and compared them.",
                        null
                    ],
                    correctText: "Great job! B is the answer because Dr. Rajendram explained two methods and compared them.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            14: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "14. Dr. Rajendram says that grammar in the Communicative Language Teaching approach is taught incidentally. Which word is most like incidentally?",
                    options: [
                        "A. Deductively",
                        "B. Occasionally ",
                        "C. Deliberately",
                        "D. Indirectly"
                    ],
                    answerIndex: 3,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. In this lecture, the opposite of incidentally is purposefully, meaning in a careful, deliberate manner. Think about what incidentally could mean. When you are ready, choose the correct answer.",
                        "This is not the correct answer. D is the answer because incidentally means casually or in an unplanned, indirect manner.",
                        null
                    ],
                    correctText: "Great job! D is the answer because incidentally means casually or in an unplanned, indirect manner.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            15: {
                subSectionTitle: "",
                type: "mcqWithHints",
                questionData: {
                    qText: "15. What is NOT a reason the professor gave about how to choose a teaching method:",
                    options: [
                        "A. availability of materials and technology",
                        "B. ease and speed of planning",
                        "C. student's ages and proficiency levels",
                        "D. students' skills, interests, and goals"
                    ],
                    answerIndex: 1,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. This was one of the reasons Dr. Rajendram listed.  \"Good teachers are always planning and adjusting their lectures based on students' needs.\" Think about which option is not necessarily related to students.",
                        "This is not the correct answer. B is the answer because it is the only reason for choosing a method that was not discussed by Dr. Rajendram.",
                        null
                    ],
                    correctText: "Great job! B is the correct answer because it is the only reason for choosing a method that was not discussed by Dr. Rajendram.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            },
            16: {
                subSectionTitle: "",
                type: "mcqWithHints",
                sectionComplete: true,
                questionData: {
                    qText: "16. What student task would be most appropriate for a teacher using the Grammar-Translation Method? ",
                    options: [
                        "A. translating a piece of poetry into the target language",
                        "B. reading a newspaper article written in the target language",
                        "C. making an appointment with a Professor in the target language",
                        "D. practicing pronunciation in the target language"
                    ],
                    answerIndex: 1,
                    hints: [
                        "This is not the correct answer. Look at your notes and try to remember the lecture. When you are ready, choose the correct answer.",
                        "This is not the correct answer. Think about the Grammar-Translation Method. What is the purpose of it? Why would a teacher use it? When you are ready, choose the correct answer.",
                        "This is not the correct answer. B is the answer because it involves reading in the target language, which Dr. Rajendram said was the \"fundamental purpose of language learning\" in the Grammar Translation Method",
                        null
                    ],
                    correctText: "Great job! B is the correct answer because it involves reading in the target language, which Dr. Rajendram said was the \"fundamental purpose of language learning\" in the Grammar Translation Method.",
                    answered: false,
                },
                userState: {
                    response: []
                }
            }
        },
        3: {
            sectionTitle: "1.4. Ask Discussion Questions",
            sectionIconTitle: "Ask",
            sectionIcon: "comment",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "Please take some time to think about the lecture that you just heard and to review your notes. You can watch the video again if you would like.",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "text",
                text: "Write 3 questions inspired by the lecture material that could be used for classroom discussion.  Do not ask any yes/no questions, and make sure the questions aren't too specific.  For example, \"What is the name of the author who wrote the paper?\" is not something you can talk about a lot with your peers. The answer is short and the conversation would be over quickly.",
                userState: {
                    viewed: false
                }
            },
            2: {
                subSectionTitle: "",
                type: "text",
                text: "When you have finished typing your questions, please review them carefully and click <b>SUBMIT</b>.",
                userState: {
                    viewed: false
                }
            },
            3: {
                subSectionTitle: "",
                type: "quiz",
                questions: [
                    {
                        qId: "0",
                        qText: "Question Number 1:",
                        qType: "open-res",
                        feedback: "",
                        submissionAlertText: "Great! What other questions can you think of?",
                        userState: {
                            response: [''] // Response is array of attempts
                        }
                    },
                ]
            },
            4: {
                subSectionTitle: "",
                type: "quiz",
                questions: [
                    {
                        qId: "1",
                        qText: "Question Number 2:",
                        qType: "open-res",
                        feedback: "",
                        submissionAlertText: "Almost there! Can you think of one more question?",
                        userState: {
                            response: [''] // Response is array of attempts
                        }
                    }
                ]
            },
            5: {
                subSectionTitle: "",
                type: "quiz",
                questions: [
                    {
                        qId: "2",
                        qText: "Question Number 3:",
                        qType: "open-res",
                        sectionComplete: true,
                        feedback: "See the <a style='padding:0;' href='/resources/oral'>Oral Resources Section</a> for a list of question forms.​",
                        submissionAlertText: "Success! You’ve submitted your three questions.",
                        userState: {
                            response: [''] // Response is array of attempts
                        }
                    }
                ]
            },
        },
        4: {
            sectionTitle: "1.5. Write a Summary",
            sectionIconTitle: "Summarize",
            sectionIcon: "comment",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "Please take some time to think about the lecture that you just heard and to review your notes. You can watch the video again if you would like.",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "video",
                srcType: "url",
                url: "videos/oral/t1.mp4",
                userState: {
                    viewed: false
                }
            },
            2: {
                subSectionTitle: "",
                type: "text",
                text: "Write a summary of the lecture in the space below. Try to remember the main points as well as key examples described. Use your lecture notes to help you. Your summary should be no more than 300 words. When you are finished, please review your work carefully and press the <b>SUBMIT</b> button.",
                userState: {
                    viewed: false
                }
            },
            3: {
                subSectionTitle: "",
                type: "quiz",
                questions: [
                    {
                        qId: "0",
                        qText: "Summary:",
                        qType: "open-res",
                        sectionComplete: true,
                        feedback: "Thank you for your summary. See the <a style='padding:0;' href='/resources/oral'>Oral Resources Section</a> for additional information about writing a summary.​",
                        userState: {
                            response: [''] // Response is array of attempts
                        }
                    }
                ]
            },
        },
        5: {
            sectionTitle: "1.6. Practice Fluency",
            sectionIconTitle: "Speak",
            sectionIcon: "readAloud",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "Read the summary that you have just written out loud. Practicing reading aloud helps to build confidence speaking, and familiarity with discussing discipline-specific content. Read the summary you have just written out loud. Be sure to speak naturally and clearly. You will only be able to record and submit one time. When you are ready, click the <b>RECORD</b> button and begin speaking. Click <b>STOP RECORDING</b> when you are done, then click the <b>SUBMIT</b> button.",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "Read Aloud",
                submitRequired: true,
                allowMultipleSubmit: true,
                text: "LOCALSTORAGE//Oral_1.5_Summary",
                saveLocation: "oral/practiceFluency/",
                userState: {
                    viewed: false
                }
            },
        },
        6: {
            sectionTitle: "1.7. Draft a Presentation",
            sectionIconTitle: "Draft",
            sectionIcon: "info",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "Think about the content from the lecture you watched, as well as your notes, comprehension questions and answers, discussion questions and summary. You will now prepare an academic presentation examining the pros and cons on of two second language teaching methods. You may choose to expand on the two methods discussed by Dr. Rajendram or introduce other methods she did not mention.  You should review the lecture, as well as the work you have completed so far. You can also find and cite additional resources to add to the presentation.<br><br>Using whatever presentation program you are comfortable with (for example, Microsoft PowerPoint, Keynote, Google Slides), prepare a 5-10 minute presentation with 5 slides. Each slide should provide different information and not include too much text. Here is what each slide should include:<br><br> <b>Slide 1: Introduction:</b> Discuss the importance of second language learning<br> <b>Slide 2: Summary of Lecture:</b> Include a summary of the lecture. You can use some of the information from the summary you prepared earlier.<br> <b>Slide 3: Pros and Cons 1:</b> Provide some pros and cons of your chosen second language teaching methods. You might want to provide a personal story or other research here.<br> <b>Slide 4: Pros and Cons 2:</b> Provide more pros and cons of your chosen second language teaching methods. You may want to provide a personal story or other research here.<br> <b>Slide 5: Conclusion:</b> Discuss some final points regarding second language teaching methods.<br><br> See the <a style='padding:0;' href='/resources/oral'>&nbsp;Oral Resources Section&nbsp;</a> for information on presentations including strategies for development, visuals and presenting.",
                userState: {
                    viewed: false
                }
            },
        },
        7: {
            sectionTitle: "1.8. Practice Presenting",
            sectionIconTitle: "Practice",
            sectionIcon: "readAloud",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "You should now take some time to practice presenting. Research suggests that practicing is the best way to improve presentation skills, especially regarding the speaker’s confidence and clarity.<br><br>Please save your presentation as a pdf and upload it to the platform. For example, to do this in Powerpoint, see the following <a target='_blank' style='padding:0;' href='https://support.microsoft.com/en-us/office/save-powerpoint-presentations-as-pdf-files-9b5c786b-9c6e-4fe6-81f6-9372f77c47c8'>link</a>.<br><br>When you are ready, practice presenting your presentation by recording and listening using the <b>RECORD</b>, <b>STOP</b> and <b>PLAY</b> buttons. Be sure to pay attention to your speed and pronunciation. You can download your practice version if you want to review it later.",
                userState: {
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "",
                type: "practicePresenting",
                saveLocation: "oral/practicePresenting/",
                fileIdentifierKey: "",
                uploadOnStop: true,
                submitOnce: false,
                userState: {
                    viewed: true
                }
            }
        },
        8: {
            sectionTitle: "1.9 Self Assess your Oral Presentation",
            sectionIconTitle: "Reflect",
            sectionIcon: "readAloud",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "Think about your speaking skills during your oral presentation preparation. How did you do? Please use the assessment below to reflect upon and rate your performance.",
                userState: {
                    viewed: true
                }
            },
            1: {
                subSectionTitle: "",
                type: "selfAssess",
                sectionTitle: "How I Used Language",
                questionArr: ['I used vocabulary accurately.', 'I used grammar accurately.', 'I used a range of vocabulary instead of repeating the same or similar forms', 'I used a range of grammar instead of repeating the same or similar forms.'],
                scale: ["Strongly Disagree", "Disagree", "Somewhat Agree", "Mostly Agree", "Strongly Agree"],
                userState: {
                    response: null
                }
            },
            2: {
                subSectionTitle: "",
                type: "selfAssess",
                sectionTitle: "How I Delivered My Speech",
                questionArr: ['I spoke at an appropriate speed (not too fast or too slow).', 'I pronounced my words clearly.', 'My speech was well organized (it had a logical order; the ideas were easy to follow).'],
                scale: ["Strongly Disagree", "Disagree", "Somewhat Agree", "Mostly Agree", "Strongly Agree"],
                userState: {
                    response: null
                }
            },
            3: {
                subSectionTitle: "",
                type: "selfAssess",
                sectionTitle: "How I Used Formal or Informal Language",
                questionArr: ['I used language that was appropriate for a graded graduate level presentation (for example, mostly Academic English, some slang may be appropriate).'],
                scale: ["Strongly Disagree", "Disagree", "Somewhat Agree", "Mostly Agree", "Strongly Agree"],
                userState: {
                    response: null
                }
            }
        },
        9: {
            sectionTitle: "2.0 Finalize and Submit your Presentation",
            sectionIconTitle: "Finalize",
            sectionIcon: "readAloud",
            userState: { locked: false, completed: false },
            0: {
                subSectionTitle: "",
                type: "text",
                text: "Make any additional final changes or edits to your presentation. You have now completed your preparation for the presentation and are ready to present.  Using the presentation program that you have selected to use, record audio for each slide. For example, to do this in PowerPoint, see the following <a target='_blank' style='padding:0;' href='https://support.microsoft.com/en-us/office/record-a-slide-show-with-narration-and-slide-timings-0b9502c6-5f6c-40ae-b1e7-e47d8741161c'>link</a>. Good Luck!",
                userState: {
                    viewed: true
                }
            },
            1: {
                subSectionTitle: "",
                type: "text",
                text: "Please click the circle below OR drag a file into the circle to attach your presentation with recording. Once selected, press <b>UPLOAD</b> to submit it to the platform.",
                userState: {
                    viewed: true
                }
            },
            2: {
                subSectionTitle: "",
                type: "uploadFile",
                fileIdentifierKey: "APlusOralFinalPresentation",
                saveLocation: "oral/finalPresentation/",
                submitOnce: true,
                userState: {
                    viewed: true
                }
            },
        },
        10: {
            sectionTitle: "2.1 Link to Completion Certificate",
            sectionIconTitle: "Complete",
            sectionIcon: "certificate",
            userState: {locked: true, completed: false},
            0: {
                subSectionTitle: "",
                type: "text",
                text: "CONGRATULATIONS! You have completed the Oral Module.  To access your Certificate of Completion, click on the button below then click on <b>Survey & Certificate</b>",
                userState:{
                    viewed: false
                }
            },
            1: {
                subSectionTitle: "​",
                type: "imgLink",
                images: [
                    {
                        src: "images/academicIntegrity/t6i8.jpg",
                        header: "",
                        link: "modulePage",
                        urlType: "internal"
                    }
                ],
                userState:{
                    viewed: false
                }
            }
        }
    }
}

export default t1;