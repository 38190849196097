import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core/';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { completeOnboarding} from '../../service/forms/dbService';


const useStyles = makeStyles({
    root: {
      padding: 10,
      display: "flex",
    },
    bullet: {
      display: 'flex',
    },
    title: {
      fontSize: 14,
    },
    

  });

class CompletionPage extends Component {

    constructor(props) {
        super(props)  
        this.state = {
            onboarding: ''
        }
    }

    handleNextButton(){
        try {
            completeOnboarding(this.props.auth.uid).then(function(){
             })
        } catch (error) {
            console.log(error)
        }   
    }

    render() {
        if (this.props.profile.onboarding === false) { return <Redirect to='/overview'></Redirect>; } 
      //  if (this.state.onboarding === 'complete') { return <Redirect to='/overview'></Redirect>; } 
        return (
            <Box justifyContent="center" alignItems="center" padding="50px" margin="50px" textAlign="center" size="small">
                
                    <div style={{ "padding-top": "20px", "padding-left": "150px", "padding-right": "150px", 'borderRadius': '10px'}}>
                        <Typography className={useStyles.title} component={'span'} color="textSecondary" gutterBottom>
                            <h3>Thank you so much for completing the student survey. Your input is valuable for our research and platform improvement. Now, we hope you enjoy the platform!</h3>
                        </Typography>
                        <Link to="/overview">
                        <Typography className={useStyles.title} component={'span'} color="primary" gutterBottom>
                                    <h3 style={{ 'cursor': 'pointer' }} onClick={() => this.handleNextButton()}>
                                        "Let's Start!" <ArrowForwardIcon></ArrowForwardIcon>
                                    </h3>
                        </Typography>
                        </Link>
                    </div>
            </Box>
        );
    }
}

const mapStateTOProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(CompletionPage);