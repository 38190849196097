import React, { Component } from "react";
import { connect } from "react-redux";
import UserInfoTab from "../../components/Util/UserInfoTab/UserInfoTab.js";
import AdminDash from "../../components/Wrappers/ProfilePage/Admin/AdminDash";
import StudentDash from "../../components/Wrappers/ProfilePage/Student/StudentDash";
import Container from "react-bootstrap/Container";

import "../../CSS_share.css";

class DashBoardHeader extends Component {
  renderUserInfoTab(userName, imageUrl, userEmail, accountNumber, accountType) {
    return (
      <UserInfoTab
        userName={userName}
        imageUrl={imageUrl}
        userEmail={userEmail}
        accountNumber={accountNumber}
        accountType={accountType}
        overview={this.props.overview}
      />
    );
  }

  renderUserDash(accountType) {
    if (accountType === "issd-dev") {
      return <AdminDash />;
    } else if (accountType === "student") {
      return <StudentDash />;
    } else if (accountType === "instructor") {
      return (
        <div>
          <h2> Instructor Account </h2>
        </div>
      );
    }
  }

  handleSideBarButton() {
    const oldState = this.state.sidebarOpen;
    this.setState({
      sidebarOpen: !oldState,
    });
  }

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  }

  render() {
    return (
      <div>
        <div id="wrapper" className={"flex-grow-1"}>
          <Container fluid>
            <br></br>
            {this.renderUserInfoTab(
              this.capitalizeFirstLetter(this.props.profile.firstName) +
                " " +
                this.capitalizeFirstLetter(this.props.profile.lastName),
              "./hellp.png",
              this.props.profile.email,
              this.props.profile.studentNumber,
              this.props.profile.accountType
            )}

            {/* {this.renderUserDash(this.props.profile.accountType)} */}
            {/* Question mark icon with tooltip */}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateTOProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateTOProps)(DashBoardHeader);
