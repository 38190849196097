
export var consentForm = {
    "formHeader": "Consent Form",
    "formType": 'consent',
    "formText": {
        "0": "Dear Students,",
        "1": "Thank you for your interest in the APLUS platform. Before using the platform, we would like to invite you to participate in a research project to improve the platform and understand students’ learning experience. \n \n"
    },
    "formSections": {
        "0": {
            "sectionHeader": "Project Consent Form",
            "sectionText": {
                "0": "The APLUS platform was developed by OISE researchers at the University of Toronto to support students’ academic communication skills, including oral and writing skills. There are three main skill building modules: Academic Integrity, Written Communication, and Oral Communication. The platform is for all students who desire to strengthen their communication skills. It brings students together to support each other and develop intercultural awareness. To improve the platform and understand students’ learning experience, we would like to collect data such as: students’ activities on the platform, user experience survey, and interviews. We will ask you to complete the user experience questionnaire before, during, and after using the platform. We will interview a group of students for an hour. \n"
            },
            "sectionQuestion": {
            }
        },
        "1": {
            "sectionHeader": "Data Use and Protection",
            "sectionText": {
                "0": "Data from this project will be kept fully confidential. Collected data will be used for platform improvement and research purposes; and may be included in conference presentations or publications. Student names and all other personal identifiable information will be removed, and the data will be completely anonymized. All data collected from this study will be kept on an encrypted, password-protected data storage space. The data will only be accessible by the research team. The de-identified data will be kept up to five years after the project ends and thereafter will be permanently deleted.",
                "1": "Participation in the project is voluntary. You are not obligated to participate in the project, and you have the right to withdraw at any time before the data is anonymized. There are no negative consequences to withdrawing. All information collected from withdrawn participants will be removed from the data depository and securely destroyed.",
                "2": "Please note that there is no monetary compensation provided for participation. For questions about research participants’ rights, you are welcome to contact the Office of Research Ethics at the University of Toronto at ethics.review@utoronto.ca or 416-946-3273. We are also happy to address and discuss any questions or concerns you may have about the research, and can be reached via email: aplusuoft.contact@gmail.com"
            },
            "sectionQuestion": {
            }
        },
        "2": {
            "sectionHeader": "Consent to Participate",
            "sectionText": {
                "0": "If you are willing to participate, please read and sign the consent form below."
            },
            "sectionQuestion": {
                
                "0": {
                    "qText": "I give permission for the data I generate, share, or post on this platform to be used for research and platform improvement.",
                    "qType": "mcq",
                    "options": ["Yes", "No"],
                    "response": null
                },
                "1": {
                    "qText": "I agree to provide feedback on my experience with the platform by completing student experience surveys.",
                    "qType": "mcq",
                    "options": ["Yes", "No"],
                    "response": null,
                    "invokeAction": "demographicForm"
                },
                "2": {
                    "qText": "I agree to participate in a group interview to further share my user experience. (to be scheduled at a later date.)",
                    "qType": "mcq",
                    "options": ["Yes", "No"],
                    "response": null
                },
                "3": {
                    "qText": "If you selected 'yes' for the question above, please enter your email address.",
                    "qType": "text",
                    "options": [],
                    "response": null,
                    "optional": true,
                }
            }
        }, 
        "3": {
                "sectionHeader": "",
                "sectionText": {
                    "0": "Thanks very much for your support. We hope you enjoy and benefit from the APLUS platform.",
                    "1": "Sincerely, \n Eunice Eunhee Jang, PhD \n \n Professor \n Ontario Institute for Studies in Education \n University of Toronto \n 252 Bloor Street West,\n Toronto, ON M5S 1V6\n eun.jang@utoronto.ca\n (416) 978-0238",
                },
                "sectionQuestion": {
                }
            }, 


    },
    "formAgreement": {
        "active": false,
        "textLine": "I agree/Agree/I consent",
        "response": false
    }
}

