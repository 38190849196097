import React, { Component } from "react";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { connect } from "react-redux";
import { adminEmails } from "../../../config/adminEmails";

//Icons
import Icon from "../../../static/images/logo/logon.png";
import KnowBuiIcon from "@material-ui/icons/MenuBookRounded";
import WrittenIcon from "@material-ui/icons/AssignmentRounded";
import OralIcon from "@material-ui/icons/RecordVoiceOverRounded";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
// Styles
import "./Sidebar.css";
import "../../../styles/global/CSS_share.css";
import { Face } from "@material-ui/icons";

const item_list = [
  {
    name: "dashboard",
    label: "Dashboard",
    iconName: EqualizerIcon,
    path: "/dashboard",
  },
  {
    name: "knowBui",
    label: "Academic Integrity",
    iconName: KnowBuiIcon,
    path: "/knowledge/AcademicIntegrity",
  },
  {
    name: "oral",
    label: "Oral Communication",
    iconName: OralIcon,
    path: "/oral/oral",
  },
  {
    name: "written",
    label: "Written Communication",
    iconName: WrittenIcon,
    path: "/written/WritingModule",
  },

  {
    name: "resources ",
    label: "Resources",
    iconName: MenuOpenIcon,
    path: "/resources",
  },
  { name: "faq ", label: "FAQ", iconName: HelpRoundedIcon, path: "/faq" },
  { name: "admin", label: "Admin Panel", iconName: Face, path: "/adminPanel" },
];

class Sidebar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      visible: true,
      visibility: "show",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleSibeBarButton = this.handleSibeBarButton.bind(this);
    this.getIndicator = this.getIndicator.bind(this);
  }

  toggleMenu() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  handleSibeBarButton(e) {
    this.toggleMenu();
    e.stopPropagation();
  }

  geticon(iconName) {
    const iconComponent = React.createElement(
      iconName,
      { class: "side-bar-icon" },
      null
    );
    return iconComponent;
  }

  getIndicator(currentPath) {
    var windowPath = window.location.pathname;
    var indicatorTrue = "sidebar-indi-none";
    // console.log('Sidebar, windowPath=',windowPath);
    // console.log('Sidebar, currentPath=',currentPath);
    if (currentPath === windowPath) indicatorTrue = "sidebar-indi";

    // console.log('Sidebar, indicatorTrue=',indicatorTrue);
    return indicatorTrue;
  }

  render() {
    const listItemComponents = item_list.map(
      (each, index) =>
        (each.name != "admin" || adminEmails.includes(this.props.email)) && (
          <div
            key={index}
            className={
              "side-bar-item-container " + this.getIndicator(each.path)
            }
          >
            <Link
              underline="none"
              to="null"
              className="hover-none"
              onClick={() => (window.location.href = each.path)}
            >
              <ListItem
                button
                key={each.name}
                className="side-bar-item center-align"
              >
                <ListItemIcon>{this.geticon(each.iconName)}</ListItemIcon>
                <ListItemText className="side-bar-text">
                  {each.label}
                </ListItemText>
              </ListItem>
            </Link>
          </div>
        )
    );

    var visibility = "sidebar_open";
    if (this.props.open === false) visibility = "sidebar_close";
    else if (this.props.open === true) visibility = "sidebar_open";

    return (
      <div id="side-bar-container">
        <div id="sideBar" className={visibility}>
          <List className="side-bar-item-list" dense>
            <div className="side-bar-item-container">
              <Link underline="none" className="hover-none" to="/overview">
                <ListItem button className="side-bar-item center-align">
                  <img
                    style={{
                      paddingTop: "10px",
                      width: "80%",
                      marginBottom: "2rem",
                    }}
                    alt="APLUS Icon"
                    src={Icon}
                  ></img>
                </ListItem>
              </Link>
            </div>
            {listItemComponents}
          </List>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.firebase.auth.email,
  };
};

export default connect(mapStateToProps)(Sidebar);
