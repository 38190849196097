import React, { Component } from "react";
import faqDocs from '../../../static/docs/faq.json';
import { Container, Row, Col } from 'react-bootstrap';
import {Typography, Grid, Card, Box, Table, TableRow, TableCell} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    cell: {
        height: '100%'
    },
    card: {
        display: 'flex',
        padding: '2rem',
        textAlign: 'center',
        justifyContent: 'center',
        marginBottom: '2rem',
        alignItems:'center',
        background: '#42425D',
        color: 'white'
    },
    cardDesc: {
        padding: '2rem',
        marginLeft: '2rem',
        marginBottom: '2rem',
        backgroundImage: 'linear-gradient(to right, #dfdfdf , white)'
    },
    container: {
    },
    row: {
        '& + &': {
            marginTop: '3rem'
        }
    }
  });
class OralResources extends Component {

    createHeading(parentElement, headingType, headingContent) {
        var heading = document.createElement(headingType);
        heading.innerHTML = headingContent;
        parentElement.appendChild(heading);
    }

    createText(parentElement, textContent) {
        var text = document.createElement("p");
        text.innerHTML = textContent;
        parentElement.appendChild(text);
    }

    renderDocContent(doc) {
        var docItems = Object.entries(doc[1])
        var docContent = document.getElementById("docs-content")
        if (docContent) {
            docContent.innerHTML = "";
            // Create Heading
            this.createHeading(docContent, "h4", doc[0])
            // Insert Doc items
            docItems.map((item, index) => {
                if (item[0].search("inner") === -1) {
                    this.createHeading(docContent, "h6", item[0])
                    docContent.appendChild(document.createElement("hr"))
                }
                this.createText(docContent, item[1].text)
                return 0;
            })
        }
    }

    render() {
        const { classes } = this.props;

        return (
        <>
            <Container style={{paddingTop: '40px'}}>
                <Typography variant='h4'>Resources</Typography>
                <hr /> <br></br>
                <Grid container alignItems='stretch'>
                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Oral Presentations</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                            <Typography variant='h6'><u>UNIVERSITY OF TORONTO: OISE APHD</u></Typography>
                                <i><p><b>Oral Presentation Workshop 1</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 27 minutes</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: Designing an oral presentation (content and structure)</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://play.library.utoronto.ca/play/0376aca69697c82c33100b21cfdf59d8'>Video</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/18XRbJqeANEeEiEls1VlqnTvLhTVSXMYh/view'>Slides</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/1xpfKUdX08c9Ne5jTFqErB060ohAvaojb/view'>Chart of Presentation Design: Steps to designing a presentation</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1hb6UDRhyGP0CwngLycrWeg7eaWSHbWYj/view">Fill out your Presentation Design Chart</a></p>

                                <i><p><b>Oral Presentation Workshop 2</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 16 minutes</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: Delivering an oral presentation  (managing nerves; verbal elements; non-verbal elements; using visual aids; practice {'&'} feedback)</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://play.library.utoronto.ca/play/f2808849cfedbfce6812ffced12f26f6'>Video</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/1Rflga2ZAqVcDHGpIssDc14_xod8ooRO-/view'>Slides (PDF)</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1BbsGD2I3X8NUKTlzPOqAYlEefLV9TbfH/view">Delivering Presentations (PDF)</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Presentation Checklist <a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1R0awymFnR0VyoNbiO7FBWU_QkPXWGlGq/view">(Document)</a></p>
                            
                                <Typography variant='h6'><u>ACADEMIC ENGLISH UK</u></Typography>
                                <p><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://academic-englishuk.com/speaking">Website offering free, downloadable lists of English phrases for presentations</a></p>

                                <Typography variant='h6'><u>UNIVERSITY OF TORONTO: SCHOOL OF GRADUATE STUDIES</u></Typography>
                                <i><p><b>Graduate Centre for Academic Communication</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://www.sgs.utoronto.ca/resources-supports/gcac/">GCAC provides U of T graduate students with advanced training in academic writing and speaking.</a></p>

                                <Typography variant='h6'><u>UNIVERSITY OF TORONTO: WRITING CENTRE</u></Typography>
                                <i><p><b>University of Toronto Writing Advice for Oral Presentations</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://advice.writing.utoronto.ca/types-of-writing/oral-presentations/">Webpage with advice for preparing and practicing an oral presentation in a U of T seminar</a></p>

                                <Typography variant='h6'><u>UNIVERSITY OF TORONTO: ENGINEERING COMMUNICATION PROGRAM</u></Typography>
                                <i><p><b>Oral Communication Handbook</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://ecp.engineering.utoronto.ca/resources/online-handbook/oral-communication/">Handbook of Information on how to effectively plan, practice, present and design visuals for an oral presentation</a></p>



                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Writing a Summary</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                        <Typography variant='h6'><u>LAS POSITAS COLLEGE</u></Typography>
                        <i><p><b>Writing a Summary</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="http://www.laspositascollege.edu/raw/summaries.php">Webpage includes advice for writing summaries, a list of reporting vocabulary, and examples of summaries.</a></p>
                        <Typography variant='h6'><u>UNIVERSITY OF TORONTO: WRITING CENTRE</u></Typography>
                        <i><p><b>Summarizing</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://advice.writing.utoronto.ca/wp-content/uploads/sites/2/summarize.pdf">Tips for effectively summarizing texts</a></p>
                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Asking Discussion Questions</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                        <Typography variant='h6'><u>CARLETON UNIVERSITY</u></Typography>
                        <i><p><b>Effective questions for leading discussions</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://carleton.ca/edc/wp-content/uploads/Effective-Questions-for-Leading-Discussions.pdf">Handout that provides teaching tips for leading discussions. 
                            It includes: types of questions, explanations of different types of questions, and some example. While this resource is designed for teachers, it can also be used by students when they are leading classroom or small group discussions.</a></p>
                        <Typography variant='h6'><u>UNIVERSITY OF WATERLOO: CENTRE FOR TEACHING EXCELLENCE</u></Typography>
                        <i><p><b>Asking more effective questions</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://uwaterloo.ca/centre-for-teaching-excellence/sites/ca.centre-for-teaching-excellence/files/uploads/files/asking_better_questions.pdf">Handout that explains the different types of questions you can ask and techniques for asking effective questions. This document also includes Bloom's Taxonomy of Thinking Skills.</a></p>
                        <Typography variant='h6'><u>WILEY EDUCATION SERVICES</u></Typography>
                        <i><p><b>Writing Discusion Forum Questions</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://ctl.wiley.com/writing-discussion-forum-questions/">This webpage focuses on creating questions for on-line that get students thinking and responding.</a></p>
                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Citations</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                        <Typography variant='h6'><u>UNIVERSITY OF TORONTO: OISE LIBRARY</u></Typography>
                        <i><p><b>Research Skills: How to search for sources and make citations</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 16 minutes</p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: refining and improving a search; when and how to cite references</p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://drive.google.com/file/d/1sDv0SEZiacbhOwtSvKot5Ur1ynnPs4cz/view'>Slides (PDF)</a></p>

                        </Grid>
                </Grid>
            </Container>

            <Container style={{ 'padding-top': "40px" }}>
                <Typography>

            

                


                </Typography>
            </Container>
        </>
        );
    }
}

export default withStyles(styles)(OralResources);