import React from 'react';
import RootNavigations from './navigations/RootNavigation.js';

function App() {
  return (
    <div> 
      <RootNavigations/> 
    </div>
  );
}

export default App;

